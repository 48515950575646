@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/firasans/FiraSans-Italic.eot');
	src: local('Fira Sans Italic'), local('FiraSans-Italic'),
		url('/assets/fonts/firasans/FiraSans-Italic.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/firasans/FiraSans-Italic.woff') format('woff'),
		url('/assets/fonts/firasans/FiraSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/firasans/FiraSans-Bold.eot');
	src: local('Fira Sans Bold'), local('FiraSans-Bold'),
		url('/assets/fonts/firasans/FiraSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/firasans/FiraSans-Bold.woff') format('woff'),
		url('/assets/fonts/firasans/FiraSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/firasans/FiraSansMedium-Italic.eot');
	src: local('Fira Sans Medium Italic'), local('FiraSansMedium-Italic'),
		url('/assets/fonts/firasans/FiraSansMedium-Italic.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/firasans/FiraSansMedium-Italic.woff') format('woff'),
		url('/assets/fonts/firasans/FiraSansMedium-Italic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/firasans/FiraSansLight.eot');
	src: local('Fira Sans Light'), local('FiraSansLight'),
		url('/assets/fonts/firasans/FiraSansLight.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/firasans/FiraSansLight.woff') format('woff'),
		url('/assets/fonts/firasans/FiraSansLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/firasans/FiraSans.eot');
	src: local('Fira Sans'), local('FiraSans'),
		url('/assets/fonts/firasans/FiraSans.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/firasans/FiraSans.woff') format('woff'),
		url('/assets/fonts/firasans/FiraSans.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/firasans/FiraSansMedium.eot');
	src: local('Fira Sans Medium'), local('FiraSansMedium'),
		url('/assets/fonts/firasans/FiraSansMedium.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/firasans/FiraSansMedium.woff') format('woff'),
		url('/assets/fonts/firasans/FiraSansMedium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/firasans/FiraSansLight-Italic.eot');
	src: local('Fira Sans Light Italic'), local('FiraSansLight-Italic'),
		url('/assets/fonts/firasans/FiraSansLight-Italic.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/firasans/FiraSansLight-Italic.woff') format('woff'),
		url('/assets/fonts/firasans/FiraSansLight-Italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('/assets/fonts/firasans/FiraSans-BoldItalic.eot');
	src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'),
		url('/assets/fonts/firasans/FiraSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/firasans/FiraSans-BoldItalic.woff') format('woff'),
		url('/assets/fonts/firasans/FiraSans-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
