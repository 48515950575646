/**
 * Global Reset of all HTML Elements
 *
 * Resetting all of our HTML Elements ensures a smoother
 * visual transition between browsers. If you don't believe me,
 * try temporarily commenting out this block of code, then go
 * and look at Mozilla versus Safari, both good browsers with
 * a good implementation of CSS. The thing is, all browser CSS
 * defaults are different and at the end of the day if visual
 * consistency is what we're shooting for, then we need to
 * make sure we're resetting all spacing elements.
 *
 */
html,
body {
  border: 0;
  font-family: "Helvetica-Neue", "Helvetica", Arial, sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
div,
span,
object,
iframe,
img,
table,
caption,
thead,
tbody,
tfoot,
tr,
tr,
td,
article,
aside,
canvas,
details,
figure,
hgroup,
menu,
nav,
footer,
header,
section,
summary,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cit,
code,
del,
dfn,
em,
ins,
q,
samp,
small,
strong,
sub,
sup,
b,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
canvas,
figure,
figure img,
figcaption,
hgroup,
footer,
header,
nav,
section,
audio,
video {
  display: block;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
table caption,
table th,
table td {
  text-align: left;
  vertical-align: middle;
}
a img {
  border: 0;
}
:focus {
  outline: 0;
}
.grid-row {
  margin-left: -20px;
  margin-right: -20px;
}
.ib-box {
  display: table;
  width: 100%;
  word-spacing: -0.36em;
}
.ib-col {
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  vertical-align: top;
  min-height: 1px;
  word-spacing: normal;
  width: 100%;
}
.fl-col {
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  min-height: 1px;
  width: 100%;
}
.ib-col[class*="push"],
.fl-col[class*="push"],
.ib-col[class*="pull"],
.fl-col[class*="pull"],
.ib-col[class*="offset"],
.fl-col[class*="offset"] {
  position: relative;
}
.xs-24 {
  width: 100%;
}
.xs-23 {
  width: 95.83333333%;
}
.xs-22 {
  width: 91.66666667%;
}
.xs-21 {
  width: 87.5%;
}
.xs-20 {
  width: 83.33333333%;
}
.xs-19 {
  width: 79.16666667%;
}
.xs-18 {
  width: 75%;
}
.xs-17 {
  width: 70.83333333%;
}
.xs-16 {
  width: 66.66666667%;
}
.xs-15 {
  width: 62.5%;
}
.xs-14 {
  width: 58.33333333%;
}
.xs-13 {
  width: 54.16666667%;
}
.xs-12 {
  width: 50%;
}
.xs-11 {
  width: 45.83333333%;
}
.xs-10 {
  width: 41.66666667%;
}
.xs-9 {
  width: 37.5%;
}
.xs-8 {
  width: 33.33333333%;
}
.xs-7 {
  width: 29.16666667%;
}
.xs-6 {
  width: 25%;
}
.xs-5 {
  width: 20.83333333%;
}
.xs-4 {
  width: 16.66666667%;
}
.xs-3 {
  width: 12.5%;
}
.xs-2 {
  width: 8.33333333%;
}
.xs-1 {
  width: 4.16666667%;
}
.xs-push-24 {
  left: 100%;
}
.xs-push-23 {
  left: 95.83333333%;
}
.xs-push-22 {
  left: 91.66666667%;
}
.xs-push-21 {
  left: 87.5%;
}
.xs-push-20 {
  left: 83.33333333%;
}
.xs-push-19 {
  left: 79.16666667%;
}
.xs-push-18 {
  left: 75%;
}
.xs-push-17 {
  left: 70.83333333%;
}
.xs-push-16 {
  left: 66.66666667%;
}
.xs-push-15 {
  left: 62.5%;
}
.xs-push-14 {
  left: 58.33333333%;
}
.xs-push-13 {
  left: 54.16666667%;
}
.xs-push-12 {
  left: 50%;
}
.xs-push-11 {
  left: 45.83333333%;
}
.xs-push-10 {
  left: 41.66666667%;
}
.xs-push-9 {
  left: 37.5%;
}
.xs-push-8 {
  left: 33.33333333%;
}
.xs-push-7 {
  left: 29.16666667%;
}
.xs-push-6 {
  left: 25%;
}
.xs-push-5 {
  left: 20.83333333%;
}
.xs-push-4 {
  left: 16.66666667%;
}
.xs-push-3 {
  left: 12.5%;
}
.xs-push-2 {
  left: 8.33333333%;
}
.xs-push-1 {
  left: 4.16666667%;
}
.xs-pull-24 {
  right: 100%;
}
.xs-pull-23 {
  right: 95.83333333%;
}
.xs-pull-22 {
  right: 91.66666667%;
}
.xs-pull-21 {
  right: 87.5%;
}
.xs-pull-20 {
  right: 83.33333333%;
}
.xs-pull-19 {
  right: 79.16666667%;
}
.xs-pull-18 {
  right: 75%;
}
.xs-pull-17 {
  right: 70.83333333%;
}
.xs-pull-16 {
  right: 66.66666667%;
}
.xs-pull-15 {
  right: 62.5%;
}
.xs-pull-14 {
  right: 58.33333333%;
}
.xs-pull-13 {
  right: 54.16666667%;
}
.xs-pull-12 {
  right: 50%;
}
.xs-pull-11 {
  right: 45.83333333%;
}
.xs-pull-10 {
  right: 41.66666667%;
}
.xs-pull-9 {
  right: 37.5%;
}
.xs-pull-8 {
  right: 33.33333333%;
}
.xs-pull-7 {
  right: 29.16666667%;
}
.xs-pull-6 {
  right: 25%;
}
.xs-pull-5 {
  right: 20.83333333%;
}
.xs-pull-4 {
  right: 16.66666667%;
}
.xs-pull-3 {
  right: 12.5%;
}
.xs-pull-2 {
  right: 8.33333333%;
}
.xs-pull-1 {
  right: 4.16666667%;
}
.xs-offset-24 {
  margin-left: 100%;
}
.xs-offset-23 {
  margin-left: 95.83333333%;
}
.xs-offset-22 {
  margin-left: 91.66666667%;
}
.xs-offset-21 {
  margin-left: 87.5%;
}
.xs-offset-20 {
  margin-left: 83.33333333%;
}
.xs-offset-19 {
  margin-left: 79.16666667%;
}
.xs-offset-18 {
  margin-left: 75%;
}
.xs-offset-17 {
  margin-left: 70.83333333%;
}
.xs-offset-16 {
  margin-left: 66.66666667%;
}
.xs-offset-15 {
  margin-left: 62.5%;
}
.xs-offset-14 {
  margin-left: 58.33333333%;
}
.xs-offset-13 {
  margin-left: 54.16666667%;
}
.xs-offset-12 {
  margin-left: 50%;
}
.xs-offset-11 {
  margin-left: 45.83333333%;
}
.xs-offset-10 {
  margin-left: 41.66666667%;
}
.xs-offset-9 {
  margin-left: 37.5%;
}
.xs-offset-8 {
  margin-left: 33.33333333%;
}
.xs-offset-7 {
  margin-left: 29.16666667%;
}
.xs-offset-6 {
  margin-left: 25%;
}
.xs-offset-5 {
  margin-left: 20.83333333%;
}
.xs-offset-4 {
  margin-left: 16.66666667%;
}
.xs-offset-3 {
  margin-left: 12.5%;
}
.xs-offset-2 {
  margin-left: 8.33333333%;
}
.xs-offset-1 {
  margin-left: 4.16666667%;
}
.xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .sm-24 {
    width: 100%;
  }
  .sm-23 {
    width: 95.83333333%;
  }
  .sm-22 {
    width: 91.66666667%;
  }
  .sm-21 {
    width: 87.5%;
  }
  .sm-20 {
    width: 83.33333333%;
  }
  .sm-19 {
    width: 79.16666667%;
  }
  .sm-18 {
    width: 75%;
  }
  .sm-17 {
    width: 70.83333333%;
  }
  .sm-16 {
    width: 66.66666667%;
  }
  .sm-15 {
    width: 62.5%;
  }
  .sm-14 {
    width: 58.33333333%;
  }
  .sm-13 {
    width: 54.16666667%;
  }
  .sm-12 {
    width: 50%;
  }
  .sm-11 {
    width: 45.83333333%;
  }
  .sm-10 {
    width: 41.66666667%;
  }
  .sm-9 {
    width: 37.5%;
  }
  .sm-8 {
    width: 33.33333333%;
  }
  .sm-7 {
    width: 29.16666667%;
  }
  .sm-6 {
    width: 25%;
  }
  .sm-5 {
    width: 20.83333333%;
  }
  .sm-4 {
    width: 16.66666667%;
  }
  .sm-3 {
    width: 12.5%;
  }
  .sm-2 {
    width: 8.33333333%;
  }
  .sm-1 {
    width: 4.16666667%;
  }
  .sm-push-24 {
    left: 100%;
  }
  .sm-push-23 {
    left: 95.83333333%;
  }
  .sm-push-22 {
    left: 91.66666667%;
  }
  .sm-push-21 {
    left: 87.5%;
  }
  .sm-push-20 {
    left: 83.33333333%;
  }
  .sm-push-19 {
    left: 79.16666667%;
  }
  .sm-push-18 {
    left: 75%;
  }
  .sm-push-17 {
    left: 70.83333333%;
  }
  .sm-push-16 {
    left: 66.66666667%;
  }
  .sm-push-15 {
    left: 62.5%;
  }
  .sm-push-14 {
    left: 58.33333333%;
  }
  .sm-push-13 {
    left: 54.16666667%;
  }
  .sm-push-12 {
    left: 50%;
  }
  .sm-push-11 {
    left: 45.83333333%;
  }
  .sm-push-10 {
    left: 41.66666667%;
  }
  .sm-push-9 {
    left: 37.5%;
  }
  .sm-push-8 {
    left: 33.33333333%;
  }
  .sm-push-7 {
    left: 29.16666667%;
  }
  .sm-push-6 {
    left: 25%;
  }
  .sm-push-5 {
    left: 20.83333333%;
  }
  .sm-push-4 {
    left: 16.66666667%;
  }
  .sm-push-3 {
    left: 12.5%;
  }
  .sm-push-2 {
    left: 8.33333333%;
  }
  .sm-push-1 {
    left: 4.16666667%;
  }
  .sm-pull-24 {
    right: 100%;
  }
  .sm-pull-23 {
    right: 95.83333333%;
  }
  .sm-pull-22 {
    right: 91.66666667%;
  }
  .sm-pull-21 {
    right: 87.5%;
  }
  .sm-pull-20 {
    right: 83.33333333%;
  }
  .sm-pull-19 {
    right: 79.16666667%;
  }
  .sm-pull-18 {
    right: 75%;
  }
  .sm-pull-17 {
    right: 70.83333333%;
  }
  .sm-pull-16 {
    right: 66.66666667%;
  }
  .sm-pull-15 {
    right: 62.5%;
  }
  .sm-pull-14 {
    right: 58.33333333%;
  }
  .sm-pull-13 {
    right: 54.16666667%;
  }
  .sm-pull-12 {
    right: 50%;
  }
  .sm-pull-11 {
    right: 45.83333333%;
  }
  .sm-pull-10 {
    right: 41.66666667%;
  }
  .sm-pull-9 {
    right: 37.5%;
  }
  .sm-pull-8 {
    right: 33.33333333%;
  }
  .sm-pull-7 {
    right: 29.16666667%;
  }
  .sm-pull-6 {
    right: 25%;
  }
  .sm-pull-5 {
    right: 20.83333333%;
  }
  .sm-pull-4 {
    right: 16.66666667%;
  }
  .sm-pull-3 {
    right: 12.5%;
  }
  .sm-pull-2 {
    right: 8.33333333%;
  }
  .sm-pull-1 {
    right: 4.16666667%;
  }
  .sm-offset-24 {
    margin-left: 100%;
  }
  .sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .sm-offset-21 {
    margin-left: 87.5%;
  }
  .sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .sm-offset-18 {
    margin-left: 75%;
  }
  .sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .sm-offset-15 {
    margin-left: 62.5%;
  }
  .sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .sm-offset-12 {
    margin-left: 50%;
  }
  .sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .sm-offset-9 {
    margin-left: 37.5%;
  }
  .sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .sm-offset-6 {
    margin-left: 25%;
  }
  .sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .sm-offset-3 {
    margin-left: 12.5%;
  }
  .sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .md-24 {
    width: 100%;
  }
  .md-23 {
    width: 95.83333333%;
  }
  .md-22 {
    width: 91.66666667%;
  }
  .md-21 {
    width: 87.5%;
  }
  .md-20 {
    width: 83.33333333%;
  }
  .md-19 {
    width: 79.16666667%;
  }
  .md-18 {
    width: 75%;
  }
  .md-17 {
    width: 70.83333333%;
  }
  .md-16 {
    width: 66.66666667%;
  }
  .md-15 {
    width: 62.5%;
  }
  .md-14 {
    width: 58.33333333%;
  }
  .md-13 {
    width: 54.16666667%;
  }
  .md-12 {
    width: 50%;
  }
  .md-11 {
    width: 45.83333333%;
  }
  .md-10 {
    width: 41.66666667%;
  }
  .md-9 {
    width: 37.5%;
  }
  .md-8 {
    width: 33.33333333%;
  }
  .md-7 {
    width: 29.16666667%;
  }
  .md-6 {
    width: 25%;
  }
  .md-5 {
    width: 20.83333333%;
  }
  .md-4 {
    width: 16.66666667%;
  }
  .md-3 {
    width: 12.5%;
  }
  .md-2 {
    width: 8.33333333%;
  }
  .md-1 {
    width: 4.16666667%;
  }
  .md-push-24 {
    left: 100%;
  }
  .md-push-23 {
    left: 95.83333333%;
  }
  .md-push-22 {
    left: 91.66666667%;
  }
  .md-push-21 {
    left: 87.5%;
  }
  .md-push-20 {
    left: 83.33333333%;
  }
  .md-push-19 {
    left: 79.16666667%;
  }
  .md-push-18 {
    left: 75%;
  }
  .md-push-17 {
    left: 70.83333333%;
  }
  .md-push-16 {
    left: 66.66666667%;
  }
  .md-push-15 {
    left: 62.5%;
  }
  .md-push-14 {
    left: 58.33333333%;
  }
  .md-push-13 {
    left: 54.16666667%;
  }
  .md-push-12 {
    left: 50%;
  }
  .md-push-11 {
    left: 45.83333333%;
  }
  .md-push-10 {
    left: 41.66666667%;
  }
  .md-push-9 {
    left: 37.5%;
  }
  .md-push-8 {
    left: 33.33333333%;
  }
  .md-push-7 {
    left: 29.16666667%;
  }
  .md-push-6 {
    left: 25%;
  }
  .md-push-5 {
    left: 20.83333333%;
  }
  .md-push-4 {
    left: 16.66666667%;
  }
  .md-push-3 {
    left: 12.5%;
  }
  .md-push-2 {
    left: 8.33333333%;
  }
  .md-push-1 {
    left: 4.16666667%;
  }
  .md-pull-24 {
    right: 100%;
  }
  .md-pull-23 {
    right: 95.83333333%;
  }
  .md-pull-22 {
    right: 91.66666667%;
  }
  .md-pull-21 {
    right: 87.5%;
  }
  .md-pull-20 {
    right: 83.33333333%;
  }
  .md-pull-19 {
    right: 79.16666667%;
  }
  .md-pull-18 {
    right: 75%;
  }
  .md-pull-17 {
    right: 70.83333333%;
  }
  .md-pull-16 {
    right: 66.66666667%;
  }
  .md-pull-15 {
    right: 62.5%;
  }
  .md-pull-14 {
    right: 58.33333333%;
  }
  .md-pull-13 {
    right: 54.16666667%;
  }
  .md-pull-12 {
    right: 50%;
  }
  .md-pull-11 {
    right: 45.83333333%;
  }
  .md-pull-10 {
    right: 41.66666667%;
  }
  .md-pull-9 {
    right: 37.5%;
  }
  .md-pull-8 {
    right: 33.33333333%;
  }
  .md-pull-7 {
    right: 29.16666667%;
  }
  .md-pull-6 {
    right: 25%;
  }
  .md-pull-5 {
    right: 20.83333333%;
  }
  .md-pull-4 {
    right: 16.66666667%;
  }
  .md-pull-3 {
    right: 12.5%;
  }
  .md-pull-2 {
    right: 8.33333333%;
  }
  .md-pull-1 {
    right: 4.16666667%;
  }
  .md-offset-24 {
    margin-left: 100%;
  }
  .md-offset-23 {
    margin-left: 95.83333333%;
  }
  .md-offset-22 {
    margin-left: 91.66666667%;
  }
  .md-offset-21 {
    margin-left: 87.5%;
  }
  .md-offset-20 {
    margin-left: 83.33333333%;
  }
  .md-offset-19 {
    margin-left: 79.16666667%;
  }
  .md-offset-18 {
    margin-left: 75%;
  }
  .md-offset-17 {
    margin-left: 70.83333333%;
  }
  .md-offset-16 {
    margin-left: 66.66666667%;
  }
  .md-offset-15 {
    margin-left: 62.5%;
  }
  .md-offset-14 {
    margin-left: 58.33333333%;
  }
  .md-offset-13 {
    margin-left: 54.16666667%;
  }
  .md-offset-12 {
    margin-left: 50%;
  }
  .md-offset-11 {
    margin-left: 45.83333333%;
  }
  .md-offset-10 {
    margin-left: 41.66666667%;
  }
  .md-offset-9 {
    margin-left: 37.5%;
  }
  .md-offset-8 {
    margin-left: 33.33333333%;
  }
  .md-offset-7 {
    margin-left: 29.16666667%;
  }
  .md-offset-6 {
    margin-left: 25%;
  }
  .md-offset-5 {
    margin-left: 20.83333333%;
  }
  .md-offset-4 {
    margin-left: 16.66666667%;
  }
  .md-offset-3 {
    margin-left: 12.5%;
  }
  .md-offset-2 {
    margin-left: 8.33333333%;
  }
  .md-offset-1 {
    margin-left: 4.16666667%;
  }
  .md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .lg-24 {
    width: 100%;
  }
  .lg-23 {
    width: 95.83333333%;
  }
  .lg-22 {
    width: 91.66666667%;
  }
  .lg-21 {
    width: 87.5%;
  }
  .lg-20 {
    width: 83.33333333%;
  }
  .lg-19 {
    width: 79.16666667%;
  }
  .lg-18 {
    width: 75%;
  }
  .lg-17 {
    width: 70.83333333%;
  }
  .lg-16 {
    width: 66.66666667%;
  }
  .lg-15 {
    width: 62.5%;
  }
  .lg-14 {
    width: 58.33333333%;
  }
  .lg-13 {
    width: 54.16666667%;
  }
  .lg-12 {
    width: 50%;
  }
  .lg-11 {
    width: 45.83333333%;
  }
  .lg-10 {
    width: 41.66666667%;
  }
  .lg-9 {
    width: 37.5%;
  }
  .lg-8 {
    width: 33.33333333%;
  }
  .lg-7 {
    width: 29.16666667%;
  }
  .lg-6 {
    width: 25%;
  }
  .lg-5 {
    width: 20.83333333%;
  }
  .lg-4 {
    width: 16.66666667%;
  }
  .lg-3 {
    width: 12.5%;
  }
  .lg-2 {
    width: 8.33333333%;
  }
  .lg-1 {
    width: 4.16666667%;
  }
  .lg-push-24 {
    left: 100%;
  }
  .lg-push-23 {
    left: 95.83333333%;
  }
  .lg-push-22 {
    left: 91.66666667%;
  }
  .lg-push-21 {
    left: 87.5%;
  }
  .lg-push-20 {
    left: 83.33333333%;
  }
  .lg-push-19 {
    left: 79.16666667%;
  }
  .lg-push-18 {
    left: 75%;
  }
  .lg-push-17 {
    left: 70.83333333%;
  }
  .lg-push-16 {
    left: 66.66666667%;
  }
  .lg-push-15 {
    left: 62.5%;
  }
  .lg-push-14 {
    left: 58.33333333%;
  }
  .lg-push-13 {
    left: 54.16666667%;
  }
  .lg-push-12 {
    left: 50%;
  }
  .lg-push-11 {
    left: 45.83333333%;
  }
  .lg-push-10 {
    left: 41.66666667%;
  }
  .lg-push-9 {
    left: 37.5%;
  }
  .lg-push-8 {
    left: 33.33333333%;
  }
  .lg-push-7 {
    left: 29.16666667%;
  }
  .lg-push-6 {
    left: 25%;
  }
  .lg-push-5 {
    left: 20.83333333%;
  }
  .lg-push-4 {
    left: 16.66666667%;
  }
  .lg-push-3 {
    left: 12.5%;
  }
  .lg-push-2 {
    left: 8.33333333%;
  }
  .lg-push-1 {
    left: 4.16666667%;
  }
  .lg-pull-24 {
    right: 100%;
  }
  .lg-pull-23 {
    right: 95.83333333%;
  }
  .lg-pull-22 {
    right: 91.66666667%;
  }
  .lg-pull-21 {
    right: 87.5%;
  }
  .lg-pull-20 {
    right: 83.33333333%;
  }
  .lg-pull-19 {
    right: 79.16666667%;
  }
  .lg-pull-18 {
    right: 75%;
  }
  .lg-pull-17 {
    right: 70.83333333%;
  }
  .lg-pull-16 {
    right: 66.66666667%;
  }
  .lg-pull-15 {
    right: 62.5%;
  }
  .lg-pull-14 {
    right: 58.33333333%;
  }
  .lg-pull-13 {
    right: 54.16666667%;
  }
  .lg-pull-12 {
    right: 50%;
  }
  .lg-pull-11 {
    right: 45.83333333%;
  }
  .lg-pull-10 {
    right: 41.66666667%;
  }
  .lg-pull-9 {
    right: 37.5%;
  }
  .lg-pull-8 {
    right: 33.33333333%;
  }
  .lg-pull-7 {
    right: 29.16666667%;
  }
  .lg-pull-6 {
    right: 25%;
  }
  .lg-pull-5 {
    right: 20.83333333%;
  }
  .lg-pull-4 {
    right: 16.66666667%;
  }
  .lg-pull-3 {
    right: 12.5%;
  }
  .lg-pull-2 {
    right: 8.33333333%;
  }
  .lg-pull-1 {
    right: 4.16666667%;
  }
  .lg-offset-24 {
    margin-left: 100%;
  }
  .lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .lg-offset-21 {
    margin-left: 87.5%;
  }
  .lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .lg-offset-18 {
    margin-left: 75%;
  }
  .lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .lg-offset-15 {
    margin-left: 62.5%;
  }
  .lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .lg-offset-12 {
    margin-left: 50%;
  }
  .lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .lg-offset-9 {
    margin-left: 37.5%;
  }
  .lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .lg-offset-6 {
    margin-left: 25%;
  }
  .lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .lg-offset-3 {
    margin-left: 12.5%;
  }
  .lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .lg-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1400px) {
  .xl-24 {
    width: 100%;
  }
  .xl-23 {
    width: 95.83333333%;
  }
  .xl-22 {
    width: 91.66666667%;
  }
  .xl-21 {
    width: 87.5%;
  }
  .xl-20 {
    width: 83.33333333%;
  }
  .xl-19 {
    width: 79.16666667%;
  }
  .xl-18 {
    width: 75%;
  }
  .xl-17 {
    width: 70.83333333%;
  }
  .xl-16 {
    width: 66.66666667%;
  }
  .xl-15 {
    width: 62.5%;
  }
  .xl-14 {
    width: 58.33333333%;
  }
  .xl-13 {
    width: 54.16666667%;
  }
  .xl-12 {
    width: 50%;
  }
  .xl-11 {
    width: 45.83333333%;
  }
  .xl-10 {
    width: 41.66666667%;
  }
  .xl-9 {
    width: 37.5%;
  }
  .xl-8 {
    width: 33.33333333%;
  }
  .xl-7 {
    width: 29.16666667%;
  }
  .xl-6 {
    width: 25%;
  }
  .xl-5 {
    width: 20.83333333%;
  }
  .xl-4 {
    width: 16.66666667%;
  }
  .xl-3 {
    width: 12.5%;
  }
  .xl-2 {
    width: 8.33333333%;
  }
  .xl-1 {
    width: 4.16666667%;
  }
  .xl-push-24 {
    left: 100%;
  }
  .xl-push-23 {
    left: 95.83333333%;
  }
  .xl-push-22 {
    left: 91.66666667%;
  }
  .xl-push-21 {
    left: 87.5%;
  }
  .xl-push-20 {
    left: 83.33333333%;
  }
  .xl-push-19 {
    left: 79.16666667%;
  }
  .xl-push-18 {
    left: 75%;
  }
  .xl-push-17 {
    left: 70.83333333%;
  }
  .xl-push-16 {
    left: 66.66666667%;
  }
  .xl-push-15 {
    left: 62.5%;
  }
  .xl-push-14 {
    left: 58.33333333%;
  }
  .xl-push-13 {
    left: 54.16666667%;
  }
  .xl-push-12 {
    left: 50%;
  }
  .xl-push-11 {
    left: 45.83333333%;
  }
  .xl-push-10 {
    left: 41.66666667%;
  }
  .xl-push-9 {
    left: 37.5%;
  }
  .xl-push-8 {
    left: 33.33333333%;
  }
  .xl-push-7 {
    left: 29.16666667%;
  }
  .xl-push-6 {
    left: 25%;
  }
  .xl-push-5 {
    left: 20.83333333%;
  }
  .xl-push-4 {
    left: 16.66666667%;
  }
  .xl-push-3 {
    left: 12.5%;
  }
  .xl-push-2 {
    left: 8.33333333%;
  }
  .xl-push-1 {
    left: 4.16666667%;
  }
  .xl-pull-24 {
    right: 100%;
  }
  .xl-pull-23 {
    right: 95.83333333%;
  }
  .xl-pull-22 {
    right: 91.66666667%;
  }
  .xl-pull-21 {
    right: 87.5%;
  }
  .xl-pull-20 {
    right: 83.33333333%;
  }
  .xl-pull-19 {
    right: 79.16666667%;
  }
  .xl-pull-18 {
    right: 75%;
  }
  .xl-pull-17 {
    right: 70.83333333%;
  }
  .xl-pull-16 {
    right: 66.66666667%;
  }
  .xl-pull-15 {
    right: 62.5%;
  }
  .xl-pull-14 {
    right: 58.33333333%;
  }
  .xl-pull-13 {
    right: 54.16666667%;
  }
  .xl-pull-12 {
    right: 50%;
  }
  .xl-pull-11 {
    right: 45.83333333%;
  }
  .xl-pull-10 {
    right: 41.66666667%;
  }
  .xl-pull-9 {
    right: 37.5%;
  }
  .xl-pull-8 {
    right: 33.33333333%;
  }
  .xl-pull-7 {
    right: 29.16666667%;
  }
  .xl-pull-6 {
    right: 25%;
  }
  .xl-pull-5 {
    right: 20.83333333%;
  }
  .xl-pull-4 {
    right: 16.66666667%;
  }
  .xl-pull-3 {
    right: 12.5%;
  }
  .xl-pull-2 {
    right: 8.33333333%;
  }
  .xl-pull-1 {
    right: 4.16666667%;
  }
  .xl-offset-24 {
    margin-left: 100%;
  }
  .xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .xl-offset-21 {
    margin-left: 87.5%;
  }
  .xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .xl-offset-18 {
    margin-left: 75%;
  }
  .xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .xl-offset-15 {
    margin-left: 62.5%;
  }
  .xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .xl-offset-12 {
    margin-left: 50%;
  }
  .xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .xl-offset-9 {
    margin-left: 37.5%;
  }
  .xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .xl-offset-6 {
    margin-left: 25%;
  }
  .xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .xl-offset-3 {
    margin-left: 12.5%;
  }
  .xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .xl-offset-0 {
    margin-left: 0%;
  }
}
.clearfix-after:after,
.grid-row:after {
  content: "";
  display: block;
  clear: both;
}
.clearfix-both:before,
.clearfix-both:after {
  content: "";
  display: block;
  clear: both;
}
.fix-inline {
  display: table;
  width: 100%;
  word-spacing: -0.36em;
}
.f-mdi {
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
}
.f-fa {
  font-family: 'FontAwesome';
  font-weight: normal;
  font-style: normal;
}
.centered {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.centered-transform {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.centered-flex {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  outline: none !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
body {
  position: relative;
  font-family: 'Fira Sans';
  font-size: 15px;
  line-height: 1.3;
  font-weight: 300;
  min-width: 320px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #191919;
}
body.body-light {
  background-color: #ffffff;
}
.wrapper {
  height: auto !important;
  height: 100%;
  min-height: 100%;
  position: relative;
  display: block;
  padding-top: 100px;
  overflow: hidden;
}
.wrapper_no-pt {
  padding-top: 0;
}
.wrapper._light {
  color: #191919;
}
@media (max-width: 767px) {
  .wrapper {
    padding-top: 68px;
  }
}
.container {
  position: relative;
  padding-right: 72px;
  padding-left: 72px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
}
.container_nopad {
  padding-right: 0;
  padding-left: 0;
}
@media (max-width: 767px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.content-narrow {
  position: relative;
  z-index: 1;
  max-width: 720px;
}
.content-narrow_min {
  max-width: 610px;
}
.content-narrow_center {
  margin-right: auto;
  margin-left: auto;
}
::selection {
  -webkit-font-smoothing: antialiased;
  color: #ffffff;
  text-shadow: none;
  background: #A36C42;
  background: rgba(163, 108, 66, 0.99);
}
::-moz-selection {
  -webkit-font-smoothing: antialiased;
  color: #ffffff;
  text-shadow: none;
  background: #A36C42;
  background: rgba(163, 108, 66, 0.99);
}
body {
  scrollbar-base-color: #E0A370;
  scrollbar-3dlight-color: transparent;
  scrollbar-highlight-color: transparent;
  scrollbar-track-color: #191919;
  scrollbar-arrow-color: #E0A370;
  scrollbar-shadow-color: transparent;
  scrollbar-dark-shadow-color: transparent;
}
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color: #E0A370;
  border-radius: 2px;
}
::-webkit-scrollbar-track {
  background-color: #191919;
}
.grid-row_no-offsets {
  margin-right: 0;
  margin-left: 0;
}
.grid-row_no-offsets > .fl-col,
.grid-row_no-offsets > .ib-box > .ib-col {
  padding-right: 0;
  padding-left: 0;
}
.grid-row_form {
  margin-right: -10px;
  margin-left: -10px;
}
.grid-row_form > .fl-col,
.grid-row_form > .ib-box > .ib-col {
  padding-right: 10px;
  padding-left: 10px;
}
.grid-row_min {
  margin-right: -4px;
  margin-left: -4px;
}
.grid-row_min > .fl-col,
.grid-row_min > .ib-box > .ib-col {
  padding-right: 4px;
  padding-left: 4px;
}
.grid-row_vam > .ib-box > .ib-col {
  vertical-align: middle;
}
.grid-row_vabot > .ib-box > .ib-col {
  vertical-align: bottom;
}
@media (max-width: 767px) {
  .grid-row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .grid-row .ib-col,
  .grid-row .fl-col {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.tb {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.tb__r {
  display: table-row;
}
.tb__c {
  display: table-cell;
  vertical-align: middle;
}
.tb_vab .tb__c {
  vertical-align: bottom;
  height: 100%;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}
.relative {
  position: relative;
}
.hidden {
  display: none !important;
}
.hidden-fade {
  visibility: hidden;
  opacity: 0;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear 0s;
  -moz-transition: opacity 0.15s linear 0s;
  -o-transition: opacity 0.15s linear 0s;
  transition: opacity 0.15s linear 0s;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
.collapse__wrap {
  padding: 20px 0 0;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
  -moz-transition: height 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
  -o-transition: height 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
  transition: height 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.warning-color {
  color: #ffaf37 !important;
}
.important-color {
  color: #de382e !important;
}
.success-color {
  color: #3FA747 !important;
}
.info-color {
  color: #1B91CC !important;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0;
  line-height: 1.16;
  font-family: 'Oranienbaum';
  text-transform: uppercase;
  color: #ffffff;
  font-weight: lighter;
}
h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child {
  margin-bottom: 0;
}
h1,
.h1 {
  font-size: 48px;
  margin-bottom: 24px;
}
@media (min-width: 768px) and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 44px;
  }
}
@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
}
h2,
.h2 {
  font-size: 42px;
  margin-bottom: 21px;
}
@media (max-width: 767px) {
  h2,
  .h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
h3,
.h3 {
  font-size: 32px;
  margin-bottom: 16px;
}
@media (min-width: 768px) and (max-width: 991px) {
  h3,
  .h3 {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  h3,
  .h3 {
    font-size: 20px;
  }
}
h4,
.h4 {
  font-size: 24px;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  h4,
  .h4 {
    font-size: 20px;
  }
}
h5,
.h5,
h6,
.h6 {
  font-size: 20px;
  margin-bottom: 16px;
}
.pretitle {
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 18px;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .pretitle {
    font-size: 15px;
    margin-bottom: 8px;
  }
}
.pretitle-icon {
  margin: 0 auto 16px;
}
.title-bold {
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 6px;
}
.title-tag {
  position: absolute;
  top: 0;
  right: -70px;
  display: block;
  background-color: rgba(175, 117, 69, 0.8);
  padding: 5px 13px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  letter-spacing: 6px;
  text-transform: uppercase;
  z-index: 2;
  font-family: 'Fira Sans';
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  transform: rotate(25deg);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
a,
.link {
  position: relative;
  display: inline-block;
}
a,
.link,
a:after,
.link:after,
a:before,
.link:before {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
a,
.link,
a:focus,
.link:focus {
  text-decoration: none;
  color: #E0A370;
}
a span,
.link span,
a:focus span,
.link:focus span {
  border-bottom: 1px solid rgba(224, 163, 112, 0.4);
}
a span,
.link span {
  -webkit-transition: border-bottom 0.3s ease-in-out 0s;
  -moz-transition: border-bottom 0.3s ease-in-out 0s;
  -o-transition: border-bottom 0.3s ease-in-out 0s;
  transition: border-bottom 0.3s ease-in-out 0s;
}
a:hover,
.link:hover {
  color: #ffffff;
  text-decoration: none;
}
a:hover span,
.link:hover span {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
a.disabled,
.link.disabled,
a.disabled:focus,
.link.disabled:focus,
a.disabled:hover,
.link.disabled:hover {
  cursor: default;
  color: rgba(255, 255, 255, 0.4);
}
._light a:hover,
._light .link:hover {
  color: #AF7545;
  text-decoration: none;
}
._light a:hover span,
._light .link:hover span {
  border-bottom-color: rgba(175, 117, 69, 0.2);
}
.link-icon {
  vertical-align: middle;
  font-size: 24px;
  line-height: 17px;
  margin-right: 8px;
}
.link-icon:last-child {
  margin-right: 0;
  margin-left: 8px;
}
.link_text,
._light .link,
.link_text:focus,
._light .link:focus {
  color: rgba(255, 255, 255, 0.7);
}
.link_text span,
._light .link span,
.link_text:focus span,
._light .link:focus span {
  border-bottom-color: rgba(255, 255, 255, 0.7);
}
.link_text:hover,
._light .link:hover {
  color: #ffffff;
}
.link_text:hover span,
._light .link:hover span {
  border-bottom-color: #ffffff;
}
.link_light,
._light .link,
.link_light:focus,
._light .link:focus {
  color: #ffffff;
}
.link_light span,
._light .link span,
.link_light:focus span,
._light .link:focus span {
  border-bottom-color: rgba(255, 255, 255, 0.7);
}
.link_light:hover,
._light .link:hover {
  color: #ffffff;
}
.link_light:hover span,
._light .link:hover span {
  border-bottom-color: #ffffff;
}
.link_dashed span,
.link_dashed:focus span {
  border-bottom-style: dashed;
}
.link_dotted span,
.link_dotted:focus span {
  border-bottom-style: dotted;
}
.link_bold {
  font-weight: bold;
}
.link_baseline {
  vertical-align: baseline;
}
.link_collapse:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  position: relative;
  width: 16px;
  height: 16px;
}
.link_collapse .link__text {
  margin-right: 8px;
}
.link_collapse.collapsed:after {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.bg {
  position: relative;
}
.bg:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: left top repeat;
}
.bg_offsets {
  padding: 50px 0;
}
.bg_gray-pattern:after {
  background-color: #F3F3F3;
  background-image: url('/assets/images/patterns/pattern-white.png');
}
.bg_white-tr:after {
  opacity: .1;
  background-image: url('/assets/images/patterns/pattern-white.png');
}
.bg_brown:after {
  background-image: url('/assets/images/patterns/pattern-brown.png');
}
.bg-img {
  background: center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.bg-img_contain {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.bg-fade {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(25, 25, 25, 0.6);
}
@media (max-width: 767px) {
  .bg_offsets {
    padding: 40px 0;
  }
}
.bg-content {
  position: absolute;
}
.menu-open {
  overflow: hidden !important;
}
.mnav {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 620px;
  background-color: #191919;
  padding: 0 72px;
  visibility: hidden;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 499;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.mnav.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.mnav__wrap {
  height: auto;
  min-height: 100%;
  position: relative;
  display: block;
  padding-top: 140px;
}
.mnav__footer {
  padding-bottom: 70px;
}
.mnav__policy {
  margin-bottom: 30px;
}
.mnav .phone-box {
  display: none;
}
@media (max-width: 767px) {
  .mnav {
    padding: 0 20px;
    text-align: center;
  }
  .mnav__wrap {
    padding-top: 92px;
  }
  .mnav__footer {
    padding-bottom: 25px;
  }
  .mnav__policy {
    margin-bottom: 20px;
  }
  .mnav .phone-box {
    display: block;
  }
}
.mmenu {
  list-style-type: none;
  padding-bottom: 40px;
}
.mmenu__link {
  display: block;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  font-size: 24px;
}
.mmenu__link,
.mmenu__link:focus {
  text-decoration: none;
  color: #E0A370;
}
.mmenu__link span,
.mmenu__link:focus span {
  border-bottom-color: rgba(224, 163, 112, 0.4);
}
.mmenu__link:hover {
  color: #ffffff;
  text-decoration: none;
}
.mmenu__link:hover span {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.mmenu__item {
  margin-bottom: 30px;
}
.mmenu__item:last-child {
  margin-bottom: 0;
}
.mmenu__item.active .mmenu__link,
.mmenu__item.active .mmenu__link:focus,
.mmenu__item.active .mmenu__link:hover {
  cursor: default;
  color: #ffffff;
}
.mmenu__item.active .mmenu__link span,
.mmenu__item.active .mmenu__link:focus span,
.mmenu__item.active .mmenu__link:hover span {
  border-bottom-color: #ffffff;
}
@media (max-width: 767px) {
  .mmenu__link {
    font-size: 18px;
  }
  .mmenu__item {
    margin-bottom: 18px;
  }
}
p,
div {
  margin: 0;
}
hr {
  border-color: rgba(255, 255, 255, 0.2);
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
.text {
  position: relative;
  z-index: 1;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
}
.text:last-child {
  margin-bottom: 0;
}
.text iframe {
  width: 100%;
  height: 400px;
  margin: 20px auto;
}
.text p,
.text ul,
.text ol {
  margin-bottom: 16px;
}
.text p:last-child,
.text ul:last-child,
.text ol:last-child {
  margin-bottom: 0;
}
.text img {
  margin: 20px auto 12px;
}
.text a {
  vertical-align: baseline;
}
.text ul,
.text ol {
  margin-left: 20px;
}
.text ul li,
.text ol li {
  margin-bottom: 8px;
}
.text ul li:last-child,
.text ol li:last-child {
  margin-bottom: 0;
}
.text blockquote {
  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #ff7863;
}
.text blockquote p {
  margin-bottom: 10px;
}
.text blockquote p:last-child {
  margin-bottom: 0;
}
.text table {
  border-collapse: collapse;
  min-width: 100%;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}
.text table td {
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.text table tr:nth-child(odd) {
  background-color: #d4d9de;
}
.text table tr:hover {
  background-color: #ccd2d7;
}
.text b,
.text strong {
  color: #ffffff;
  font-weight: 500;
}
.text_l {
  font-size: 18px;
}
@media (max-width: 767px) {
  .text_l {
    font-size: 15px;
  }
}
.text_xl {
  font-size: 24px;
}
@media (max-width: 767px) {
  .text_xl {
    font-size: 18px;
  }
}
.text_s {
  font-size: 13px;
}
.text_s p,
.text_s ul,
.text_s ol {
  margin-bottom: 8px;
}
.text_s img {
  margin: 12px auto;
}
.text_italic {
  font-style: italic;
}
.text_light-fade {
  color: rgba(255, 255, 255, 0.7);
}
._light .text {
  color: #191919;
  font-weight: 400;
}
._light .text h1,
._light .text .h1,
._light .text h2,
._light .text .h2,
._light .text h3,
._light .text .h3,
._light .text h4,
._light .text .h4,
._light .text h5,
._light .text .h5,
._light .text h6,
._light .text .h6,
._light .text b,
._light .text strong {
  color: #191919;
}
.text_ul-mod ul {
  margin-left: 0;
  list-style-type: none;
}
.text_ul-mod ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
}
.text_ul-mod ul li:last-child {
  margin-bottom: 0;
}
.text_ul-mod ul li:after {
  display: block;
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: 8px;
  left: 3px;
  background-color: #E0A370;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media (max-width: 767px) {
  .text img {
    margin: 16px auto;
  }
}
.text-ellipsis {
  display: block;
  max-width: 100%;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.note {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}
.note_btn {
  display: inline-block;
  vertical-align: top;
  max-width: 230px;
}
@media (max-width: 767px) {
  .note_btn {
    max-width: 100%;
  }
}
.styled-list {
  list-style-type: none;
}
.styled-list li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
}
.styled-list li:last-child {
  margin-bottom: 0;
}
.styled-list li:after {
  display: block;
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: 8px;
  left: 3px;
  background-color: #E0A370;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.table-wrap {
  overflow-y: auto;
  margin: 16px 0;
}
.blockquote {
  position: relative;
  font-size: 24px;
}
.blockquote__b {
  position: relative;
}
.blockquote__b:after,
.blockquote__b:before {
  content: "";
  z-index: 0;
  position: absolute;
  opacity: .1;
  background-color: #fff;
  width: 98px;
  height: 120px;
  background: url('/assets/images/icons/sprite-icons.png') no-repeat !important;
  display: block;
  -webkit-background-size: 115px !important;
  -moz-background-size: 115px !important;
  -o-background-size: 115px !important;
  background-size: 115px !important;
  background-position: -5px -794px !important;
  width: 91px !important;
  height: 99px !important;
}
.blockquote__b:before {
  left: -40px;
  top: 0;
}
.blockquote__b:after {
  right: 0;
  bottom: -50px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.blockquote__img {
  max-height: 320px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .blockquote__text {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .blockquote {
    font-size: 18px;
  }
  .blockquote__text {
    margin-bottom: 20px;
  }
  .blockquote__b:before {
    left: -20px;
  }
  .blockquote__b:after {
    bottom: -20px;
  }
}
.header {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100px;
  background-color: transparent;
  z-index: 500;
  max-width: 100%;
  min-width: 320px;
  padding: 20px 25px;
  overflow: hidden;
}
.header__wrap {
  position: relative;
  height: 100%;
}
.header__wrap:after {
  content: "";
  display: block;
  clear: both;
}
.header_fixed {
  position: fixed;
}
@media (max-width: 767px) {
  .header {
    padding: 20px 20px 0;
    height: 68px;
  }
  .header .phone-box {
    display: none;
  }
}
.phone-box {
  position: relative;
  padding-right: 48px;
  text-align: right;
  max-width: 240px;
  float: right;
}
.phone-box__phone {
  font-family: 'Oranienbaum';
  font-size: 24px;
  white-space: nowrap;
}
.phone-box__phone,
.phone-box__phone:focus {
  color: #ffffff;
}
.phone-box__phone:hover {
  color: #E0A370;
}
.phone-box__icon {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: url('/assets/images/icons/sprite-icons.png') no-repeat;
  -webkit-background-size: 115px;
  -moz-background-size: 115px;
  -o-background-size: 115px;
  background-size: 115px;
  background-position: -5px -630px;
  width: 32px;
  height: 32px;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
  -moz-transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
  -o-transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
  transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
}
.phone-box:hover .phone-box__icon {
  -webkit-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.phone-box__note {
  color: rgba(255, 255, 255, 0.7);
}
.phone-box_modal {
  max-width: none;
  padding: 0;
  text-align: left;
  float: none;
}
.phone-box_modal:before {
  display: none;
}
.phone-box_modal .phone-box__phone {
  font-size: 32px;
}
.phone-box_modal .phone-box__phone:hover {
  color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 767px) {
  .phone-box {
    padding-right: 0;
    text-align: center;
    margin-bottom: 16px;
    float: none;
    max-width: none;
  }
  .phone-box:before {
    position: relative;
    left: auto;
    right: auto;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .phone-box__phone {
    font-size: 20px;
    margin-bottom: 8px;
  }
  .phone-box_modal {
    text-align: left;
  }
  .phone-box_modal .phone-box__phone {
    font-size: 24px;
  }
}
.logo {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 0;
  width: 168px;
  height: 56px;
  background: transparent url('/assets/images/logo-small.png') center no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.logo:hover {
  opacity: .8;
}
@media (max-width: 767px) {
  .logo {
    background-image: url('/assets/images/logo-mob.png');
    height: 30px;
  }
}
.footer {
  position: relative;
  min-width: 320px;
  z-index: 0;
  background-color: #191919;
}
.footer__dnext {
  float: right;
  margin-top: 10px;
  font-size: 11px;
}
.footer_fixed {
  position: fixed;
  height: 124px;
  margin-top: -124px;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 498;
  background-color: transparent;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footer_fixed {
    position: relative;
    height: auto;
    margin-top: 0;
  }
  .footer__dnext {
    float: none;
  }
}
.footer-wrap {
  position: relative;
  padding: 25px 25px 25px;
}
.footer-wrap:after {
  content: "";
  display: block;
  clear: both;
}
.f-contacts {
  display: inline-block;
  vertical-align: top;
}
.f-contacts__social,
.f-contacts__copy {
  display: inline-block;
  vertical-align: middle;
  margin-right: 26px;
}
.f-contacts__social:last-child,
.f-contacts__copy:last-child {
  margin-right: 0;
}
.f-contacts__text {
  font-size: 11px;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .f-contacts {
    margin-bottom: 12px;
  }
  .f-contacts:last-child {
    margin-bottom: 0;
  }
  .f-contacts__social,
  .f-contacts__copy {
    display: block;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .f-contacts__social:last-child,
  .f-contacts__copy:last-child {
    margin-bottom: 0;
  }
}
.sbox {
  position: relative;
  margin-top: 72px;
  margin-bottom: 72px;
}
.sbox__title {
  margin-bottom: 40px;
}
.sbox_bgi {
  background: #000000 right center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 72px;
  padding-bottom: 72px;
}
.sbox_page {
  margin-top: 40px;
}
.sbox_modal {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 48px;
  padding-bottom: 48px;
}
.sbox_content {
  margin: 0 !important;
  padding: 40px 0 72px;
}
.sbox_last-nomb {
  margin-bottom: 0;
}
.sbox_wrapper {
  margin-top: -100px;
  padding-top: 100px;
}
@media (max-width: 767px) {
  .sbox {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .sbox_bgi {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .sbox_wrapper {
    margin-top: -68px;
    padding-top: 68px;
  }
  .sbox_page {
    margin-top: 24px;
  }
  .sbox_content {
    padding-bottom: 48px;
  }
  .sbox_modal {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.list-el {
  margin-bottom: 40px;
}
.list-el,
.list-el__wrap {
  position: relative;
}
.list-el span:not(.list-el__title),
.list-el:focus span:not(.list-el__title) {
  border-bottom: none;
}
.list-el span:not(.list-el__title) {
  display: block;
}
.list-el__wrap {
  display: block;
}
.list-el__link {
  position: relative;
  display: block;
}
.list-el__img {
  background: center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 225px;
  margin-bottom: 20px;
}
.list-el__text,
.list-el__price {
  color: rgba(255, 255, 255, 0.7);
}
.list-el__pos {
  margin-top: 8px;
}
.list-el__pos,
.list-el__phone,
.list-el__email {
  margin-bottom: 8px;
}
.list-el__pos:last-child,
.list-el__phone:last-child,
.list-el__email:last-child {
  margin-bottom: 0;
}
.list-el__name {
  color: #ffffff;
  font-weight: 600;
}
.list-el__pos {
  color: rgba(255, 255, 255, 0.7);
}
.list-el__phone a,
.list-el__phone a:focus {
  color: rgba(255, 255, 255, 0.7);
}
.list-el__phone a:hover {
  color: #ffffff;
}
.list-el__email a span {
  display: inline !important;
  border-bottom: 1px solid rgba(224, 163, 112, 0.4) !important;
}
.list-el__email a:hover span {
  border-bottom-color: rgba(255, 255, 255, 0.2) !important;
}
.list-el_slider {
  text-align: center;
  font-size: 18px;
}
.list-el_slider .list-el__price {
  margin-top: 11px;
}
.list-el_slider .list-el__text {
  margin-top: 3px;
}
.list-el_slider .list-el__img {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.list-el_center {
  text-align: center;
}
.list-el_block {
  background-color: #191919;
  text-align: center;
  margin-bottom: 8px;
  height: 520px;
}
.list-el_block .list-el__wrap {
  max-width: 400px;
  margin: 0 auto;
}
.list-el_block .list-el__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  margin-bottom: 0;
  background: transparent center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.list-el_block .list-el__img,
.list-el_block .list-el__img.aos-animate {
  opacity: .4;
}
.list-el_block .list-el__link {
  padding: 20.5% 20px;
  height: 100%;
}
.list-el_block .list-el__link:hover .list-el__img {
  opacity: .2;
}
.list-el_block .list-el__text {
  min-height: 236px;
  padding-bottom: 86px;
}
.list-el_block .advan-el {
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  margin: auto;
}
.list-el_contacts {
  min-height: 70px;
  padding-left: 96px;
  margin-bottom: 26px;
}
.list-el_contacts:last-child {
  margin-bottom: 0;
}
.list-el_contacts .avatar {
  position: absolute;
  top: -8px;
  left: 0;
  margin-bottom: 0;
}
.list-el_contacts .list-el__name {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
}
@media (min-width: 768px) and (max-width: 991px) {
  .list-el_block {
    height: 535px;
  }
}
@media (max-width: 767px) {
  .list-el_block {
    height: auto;
  }
  .list-el_block .list-el__link {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .list-el_block .list-el__text {
    min-height: auto;
    padding-bottom: 86px;
  }
  .list-el_block .advan-el {
    bottom: 50px;
  }
}
.svg-icon {
  stroke-width: 1px;
  stroke: none;
  fill: black;
  fill-rule: evenodd;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}
.btn:hover .svg-icon,
.link:hover .svg-icon {
  fill: #ffffff;
}
.disabled .svg-icon,
.disabled .svg-icon:hover {
  fill: rgba(255, 255, 255, 0.4);
}
.svg-icon_stroke {
  stroke: black;
  fill: none;
}
.btn:hover .svg-icon_stroke,
.link:hover .svg-icon_stroke {
  stroke: #ffffff;
  fill: none;
}
.disabled .svg-icon_stroke,
.disabled:hover .svg-icon_stroke {
  stroke: rgba(255, 255, 255, 0.4);
  fill: none;
}
.svg-icon_min .svg-box__icon {
  width: 16px;
  height: 16px;
}
.iconed__t,
.iconed__i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}
.iconed__t:last-child,
.iconed__i:last-child {
  margin-right: 0;
}
.link .iconed,
.link:focus .iconed {
  border-bottom: none;
}
.link .iconed__i,
.link:focus .iconed__i {
  border-bottom: none;
}
.btn .iconed__i {
  line-height: 17px;
}
.icon {
  background: url('/assets/images/icons/sprite-icons.png') no-repeat !important;
  display: block;
  -webkit-background-size: 115px !important;
  -moz-background-size: 115px !important;
  -o-background-size: 115px !important;
  background-size: 115px !important;
}
.icon-star {
  background-position: -5px -5px !important;
  width: 48px !important;
  height: 48px !important;
}
.icon-archi-logo {
  background-position: -5px -63px !important;
  width: 86px !important;
  height: 77px !important;
}
.icon-compass {
  background-position: -5px -150px !important;
  width: 86px !important;
  height: 86px !important;
}
.icon-diamond {
  background-position: -5px -246px !important;
  width: 86px !important;
  height: 86px !important;
}
.icon-hand {
  background-position: -5px -342px !important;
  width: 86px !important;
  height: 86px !important;
}
.icon-list {
  background-position: -5px -438px !important;
  width: 86px !important;
  height: 86px !important;
}
.icon-money {
  background-position: -5px -534px !important;
  width: 86px !important;
  height: 86px !important;
}
.icon-phone {
  background-position: -5px -630px !important;
  width: 32px !important;
  height: 32px !important;
}
.icon-pretitle {
  background-position: -5px -672px !important;
  width: 98px !important;
  height: 24px !important;
}
.icon-project {
  background-position: -5px -706px !important;
  width: 86px !important;
  height: 86px !important;
}
.icon-compass-big {
  background-position: -3px -902px !important;
  width: 110px !important;
  height: 127px !important;
}
@media (max-width: 991px) {
  .icon-compass-big {
    background-position: -4px -1043px !important;
    width: 62px !important;
    height: 79px !important;
  }
}
.icon-play {
  background-position: -5px -1131px !important;
  width: 86px !important;
  height: 86px !important;
}
.icon-quote {
  background-position: -5px -794px !important;
  width: 91px !important;
  height: 99px !important;
}
.advan-el span:not(.advan-el__title),
.advan-el:focus span:not(.advan-el__title) {
  border-bottom: none;
}
.advan-el,
.advan-el__wrap {
  position: relative;
}
.advan-el__wrap,
.advan-el__icon,
.advan-el__num,
.advan-el__text {
  display: block;
}
.advan-el__title {
  margin-bottom: 16px;
}
.advan-el__icon {
  width: 86px;
  height: 86px;
  margin: 0 auto 16px;
  background: transparent center no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.advan-el__icon:last-child {
  margin-bottom: 0;
}
.advan-el__num {
  font-size: 64px;
  line-height: 1;
  white-space: nowrap;
  font-family: 'Oranienbaum';
  color: #E0A370;
}
.advan-el_nums {
  margin-bottom: 24px;
  text-align: center;
}
.advan-el_single {
  max-width: 86px;
  margin: 16px auto 0;
}
.advan-el_home {
  margin-bottom: 20px;
}
.advan-el_home .advan-el__icon {
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
  -moz-transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
  -o-transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
  transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
}
.advan-el_home:hover .advan-el__icon {
  -webkit-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.advan-el_catalog {
  margin-bottom: 24px;
  text-align: center;
}
.advan-el_link {
  display: block;
  max-width: 160px;
  margin: 0 auto;
}
.advan-el_link,
.advan-el_link:focus,
._light .advan-el_link,
._light .advan-el_link:focus {
  text-decoration: none;
  color: #E0A370;
}
.advan-el_link span,
.advan-el_link:focus span,
._light .advan-el_link span,
._light .advan-el_link:focus span {
  border-bottom-color: rgba(224, 163, 112, 0.4);
}
.advan-el_link .advan-el__icon {
  margin-bottom: 12px;
}
.advan-el_anim:hover .advan-el__icon {
  -webkit-animation: scale-up 0.5s cubic-bezier(1, 0, 0, 0.5);
  -moz-animation: scale-up 0.5s cubic-bezier(1, 0, 0, 0.5);
  animation: scale-up 0.5s cubic-bezier(1, 0, 0, 0.5);
}
@media (max-width: 767px) {
  .advan-el {
    font-size: 13px;
  }
  .advan-el__num {
    font-size: 48px;
    margin-bottom: 16px;
  }
  .advan-el__icon {
    margin-bottom: 10px;
  }
}
.advans-box {
  margin-bottom: -24px;
}
@media (max-width: 991px) {
  .advans-box_about-dev {
    margin-top: 20px;
  }
}
.docs {
  margin-bottom: -12px;
}
.file-item {
  position: relative;
  margin-bottom: 12px;
}
.file-item span:not(.file-item__title),
.file-item:focus span:not(.file-item__title) {
  border-bottom: none;
}
.file-item__icon {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  background: center no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.file-item__icon:last-child {
  margin-right: 0;
}
.file-item__type {
  display: block;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
}
.file-item_single {
  max-width: 320px;
}
.file-item_on-light .file-item__type {
  color: #191919;
}
.files-list {
  margin-bottom: -30px;
}
.files-list .file-item {
  margin-bottom: 30px;
}
.tabs {
  position: relative;
  list-style-type: none;
  margin-bottom: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tabs__item {
  display: inline-block;
  vertical-align: top;
  width: auto;
  margin-right: 16px;
}
.tabs__item:last-child {
  margin-bottom: 0;
}
.tabs__item:last-child {
  margin-right: 0;
}
.tabs__link,
._light .tabs__link {
  display: block;
  padding: 0 0 6px;
  margin-bottom: 0;
}
.tabs__link,
._light .tabs__link,
.tabs__link:focus,
._light .tabs__link:focus {
  color: #ffffff;
}
.tabs__link span,
._light .tabs__link span,
.tabs__link:focus span,
._light .tabs__link:focus span {
  border-bottom-color: rgba(255, 255, 255, 0.4);
}
.tabs__link:hover span,
._light .tabs__link:hover span {
  border-bottom-color: #ffffff;
}
.active > .tabs__link,
.active > ._light .tabs__link,
.active > .tabs__link:focus,
.active > ._light .tabs__link:focus,
.active > .tabs__link:hover,
.active > ._light .tabs__link:hover {
  color: #000000;
  cursor: default;
  border-bottom: 2px solid #000000;
}
.active > .tabs__link span,
.active > ._light .tabs__link span {
  border-bottom: none;
}
.tabs_on-dark {
  margin-bottom: 0;
}
.tabs_on-dark .tabs__link,
.tabs_on-dark .tabs__link:focus {
  color: #E0A370;
  border-bottom: 3px solid transparent;
}
.tabs_on-dark .tabs__link span,
.tabs_on-dark .tabs__link:focus span {
  border-bottom-color: rgba(224, 163, 112, 0.4);
}
.tabs_on-dark .tabs__link:hover {
  color: #ffffff;
}
.tabs_on-dark .tabs__link:hover span {
  border-bottom-color: #ffffff;
}
.tabs_on-dark .tabs__item.active .tabs__link,
.tabs_on-dark .tabs__item.active .tabs__link:focus {
  color: #ffffff;
  cursor: default;
  border-bottom-color: #ffffff;
}
.tabs_on-dark .tabs__item.active .tabs__link span {
  border-bottom-color: transparent;
}
.tabs_floors {
  text-align: center;
}
.tabs_floors .tabs__item {
  vertical-align: middle;
  margin-right: 20px;
}
.tabs_floors .tabs__item.active .tabs__link,
.tabs_floors .tabs__item.active .tabs__link:focus,
.tabs_floors .tabs__item.active .tabs__link:hover {
  color: #ffffff;
  cursor: default;
  border-bottom: none;
}
.tabs_floors .tabs__item.active .tabs__link span {
  border-bottom-color: transparent;
}
.tabs_floors .tabs__item.active .tabs__link:after {
  visibility: visible;
  opacity: 1;
}
.tabs_floors .tabs__link {
  position: relative;
  font-family: 'Oranienbaum';
  font-size: 24px;
  line-height: 1;
  padding-bottom: 0;
}
.tabs_floors .tabs__link:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -16px;
  margin-left: -16px;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  visibility: hidden;
  opacity: 0;
}
.tabs_floors .tabs__link,
.tabs_floors .tabs__link:focus {
  color: #E0A370;
  border-bottom: none;
}
.tabs_floors .tabs__link span,
.tabs_floors .tabs__link:focus span {
  border-bottom-color: rgba(224, 163, 112, 0.4);
}
.tabs_floors .tabs__link:hover {
  color: #ffffff;
  border-bottom: none;
}
.tabs_floors .tabs__link:hover span {
  border-bottom-color: #ffffff;
}
.tabs_floors .tabs__link span {
  position: relative;
  z-index: 1;
}
.tabs_vert .tabs__item {
  margin-bottom: 16px;
  margin-right: 0;
  display: block;
}
.tabs-slider {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  height: auto;
  width: 100%;
}
.tabs-slider:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 0;
}
.tabs-slider__name {
  font-weight: 600;
}
.tabs-slider__pos {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: none !important;
}
.tabs-slider__slide {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 15px;
  width: 220px;
  margin-right: 40px;
}
.tabs-slider__slide:last-child {
  margin-right: 0;
}
.tabs-slider__slide:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 20;
}
.tabs-slider__slide.active {
  cursor: default;
}
.tabs-slider__slide.active:after {
  visibility: visible;
  opacity: 1;
}
.tabs-slider__slide.active .tabs-slider__name {
  border-bottom-color: transparent;
  color: #ffffff;
}
@media (max-width: 767px) {
  .tabs-slider {
    margin-bottom: 24px;
  }
}
.breadcrumbs {
  position: relative;
  margin-bottom: 10px;
}
.breadcrumbs__item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-right: 23px;
  margin-right: 13px;
}
.breadcrumbs__item:last-child {
  margin-right: 0;
}
.breadcrumbs__item:after {
  content: "\F054";
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  display: block;
  position: absolute;
  right: 0;
  top: 1px;
  font-size: 16px;
  line-height: 1;
  width: 16px;
  height: 16px;
  color: #ffffff;
}
.breadcrumbs span:not(.breadcrumbs__text),
.breadcrumbs:focus span:not(.breadcrumbs__text) {
  border-bottom: none;
}
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -10%);
  -moz-transform: translate(0, -10%);
  -ms-transform: translate(0, -10%);
  -o-transform: translate(0, -10%);
  transform: translate(0, -10%);
  -webkit-transition: -webkit-transform 0.3s ease-out 0s;
  -moz-transition: -moz-transform 0.3s ease-out 0s;
  -o-transition: -o-transform 0.3s ease-out 0s;
  transition: -ms-transform 0.3s ease-out 0s, transform 0.3s ease-out 0s;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  padding-top: 48px;
  min-height: 205px;
  z-index: 1;
}
.modal__wrapper {
  min-height: 100%;
  padding-top: 72px;
  padding-bottom: 277px;
  overflow: hidden;
}
.modal_fw .modal-dialog {
  max-width: 100%;
  height: 100%;
  background-color: #191919;
}
.modal_content .modal-dialog {
  max-width: 835px;
}
.modal_light .modal-dialog {
  background-color: #ffffff;
}
.modal_centerd {
  text-align: center;
}
.modal_centerd:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.36em;
}
.modal_centerd .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal_right {
  left: auto;
  margin-right: auto;
}
.modal_right.fade .modal-dialog {
  -webkit-transform: translate(30%, 0);
  -moz-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  -o-transform: translate(30%, 0);
  transform: translate(30%, 0);
}
.modal_right.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal_right .modal-dialog {
  min-height: 100%;
}
.modal_min .modal-dialog {
  max-width: 500px;
  border-radius: 2px;
  padding-top: 60px;
  padding-bottom: 60px;
}
.modal .form-question:before {
  display: none;
}
.modal .form-question__cont {
  max-width: 100%;
}
@media (max-width: 767px) {
  .modal__wrapper {
    padding-top: 48px;
    padding-bottom: 197px;
  }
  .modal__footer {
    padding-top: 32px;
    min-height: 165px;
  }
  .modal_min .modal-dialog {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}
.modal-dialog {
  display: block;
  position: relative;
  max-width: 600px;
  background-color: #AF7545;
  margin: 0 auto;
  border-radius: 0 0 2px 2px;
  padding: 0;
}
@media (max-width: 767px) {
  .modal-dialog {
    max-width: 100%;
  }
}
.modal-close {
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 32px;
  width: 32px;
  height: 32px;
  line-height: 1;
  z-index: 100;
  opacity: 1;
}
.modal-close,
._light .modal-close,
.modal-close:focus,
._light .modal-close:focus {
  color: #ffffff;
}
.modal-close:hover,
._light .modal-close:hover {
  color: #ffffff;
  opacity: .8;
}
@media (max-width: 767px) {
  .modal-close {
    top: 10px;
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(25, 25, 25, 0.6);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.in {
  opacity: 1;
}
.modal-open {
  overflow: hidden !important;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.preloader {
  position: relative;
  margin: auto;
  width: 28px;
  height: 28px;
}
.preloader__item {
  display: block;
  width: 100%;
  height: 100%;
}
.preloader__item:before,
.preloader__item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #000000;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.preloader__item:before {
  -webkit-animation: preloaderScale 1.5s linear 0s infinite;
  -moz-animation: preloaderScale 1.5s linear 0s infinite;
  animation: preloaderScale 1.5s linear 0s infinite;
}
.preloader__item:after {
  opacity: .4;
  -webkit-animation: preloaderScale 1.5s linear 0.5s infinite;
  -moz-animation: preloaderScale 1.5s linear 0.5s infinite;
  animation: preloaderScale 1.5s linear 0.5s infinite;
}
.preloader_offsets {
  width: 60px;
  height: 60px;
  margin-top: 60px;
  margin-bottom: 60px;
}
.preloader_btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
.preloader_btn .preloader__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 24px;
  height: 24px;
}
.preloader_indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 10;
  width: 20px;
  height: 20px;
}
.preloader_centered {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: auto;
  height: auto;
}
.preloader_centered .preloader__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 60px;
  height: 60px;
}
.preloader_centered-light {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: auto;
  height: auto;
}
.preloader_centered-light .preloader__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 60px;
  height: 60px;
}
.preloader_centered-light .preloader__item:before,
.preloader_centered-light .preloader__item:after {
  border-color: #ffffff;
}
@-moz-keyframes preloaderScale {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  25% {
    -webkit-transform: scale(0.7, 0.7);
    -moz-transform: scale(0.7, 0.7);
    -ms-transform: scale(0.7, 0.7);
    -o-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    opacity: .9;
  }
  50% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: .4;
  }
}
@-webkit-keyframes preloaderScale {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  25% {
    -webkit-transform: scale(0.7, 0.7);
    -moz-transform: scale(0.7, 0.7);
    -ms-transform: scale(0.7, 0.7);
    -o-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    opacity: .9;
  }
  50% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: .4;
  }
}
@keyframes preloaderScale {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  25% {
    -webkit-transform: scale(0.7, 0.7);
    -moz-transform: scale(0.7, 0.7);
    -ms-transform: scale(0.7, 0.7);
    -o-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    opacity: .9;
  }
  50% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: .4;
  }
}
.params {
  margin-bottom: 30px;
}
.params__item {
  display: table;
  width: 100%;
  word-spacing: -0.36em;
  margin-bottom: 16px;
}
.params__item:after {
  content: "";
  display: block;
  clear: both;
}
.params__item:last-of-type {
  margin-bottom: 0;
}
.params__name,
.params__val {
  word-spacing: normal;
  display: inline-block;
  vertical-align: bottom;
}
.params__name {
  overflow: hidden;
  width: 58.33333333%;
  padding-right: 5px;
}
.params__name:after {
  content: '';
  display: inline-block;
  vertical-align: baseline;
  width: 100%;
  height: 3px;
  border-bottom: 2px dotted rgba(255, 255, 255, 0.7);
  opacity: .3;
  margin-right: -100%;
}
.params__val {
  width: 41.66666667%;
}
.page-head {
  position: relative;
  margin-top: -100px;
  padding: 150px 0 100px;
  background-color: #191919;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.7);
}
.page-head__img {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: transparent center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.page-head__img,
.page-head__img.aos-animate[data-aos] {
  opacity: .4;
}
.page-head__title-text {
  position: relative;
}
.page-head__content {
  margin-bottom: 45px;
}
.page-head__gal {
  position: absolute;
  z-index: 2;
  bottom: 54px;
  right: 20px;
  max-width: 215px;
  padding-left: 96px;
  font-size: 18px;
}
.page-head__gal-icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 80px;
  height: 55px;
  border-bottom: none !important;
  background: transparent center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.page-head__wrap.tb {
  height: 1px;
}
.page-head_big {
  padding-bottom: 150px;
}
.page-head_big .page-head__wrap {
  min-height: 500px;
}
.page-head_big .page-head__img,
.page-head_big .page-head__img.aos-animate {
  opacity: .4;
}
.page-head_no-mb {
  margin-bottom: 0;
}
.page-head_no-pb {
  padding-bottom: 0 !important;
}
.page-head__wrap {
  position: relative;
  z-index: 1;
}
.page-head_slider .page-head__wrap,
.page-head_catalog .page-head__wrap {
  padding: 0 235px;
  min-height: 300px;
}
.page-head_slider .page-head__img,
.page-head_catalog .page-head__img,
.page-head_slider .page-head__img.aos-animate,
.page-head_catalog .page-head__img.aos-animate {
  opacity: .075;
}
.page-head_slider {
  margin-bottom: 131px;
}
.page-head_slider .page-head__content {
  margin-bottom: 16px;
}
.page-head_catalog {
  margin-bottom: 0;
  padding-bottom: 40px;
}
.page-head_catalog .page-head__title {
  margin-bottom: 10px;
}
.page-head_modal {
  margin: 0;
  padding: 0;
}
.page-head_modal .page-head__wrap {
  padding: 60px 0;
  min-height: 500px;
}
@media (max-width: 991px) {
  .page-head__gal {
    display: block;
    position: relative;
    right: 0;
    left: 0;
    bottom: auto;
    margin: 40px auto 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .page-head {
    padding: 106px 0 70px;
  }
  .page-head_big .page-head__title {
    text-align: center;
  }
  .page-head_big .page-head__wrap {
    min-height: auto;
  }
  .page-head_slider .page-head__wrap,
  .page-head_catalog .page-head__wrap {
    padding: 0 72px;
  }
  .page-head_catalog {
    padding-bottom: 40px;
  }
  .page-head_modal {
    margin: 0;
    padding: 0;
  }
  .page-head_modal .page-head__wrap {
    padding: 60px 0;
    min-height: 500px;
  }
}
@media (max-width: 767px) {
  .page-head {
    padding: 92px 0 50px;
  }
  .page-head_big .page-head__title {
    text-align: center;
  }
  .page-head_big .page-head__wrap {
    min-height: auto;
  }
  .page-head_slider .page-head__wrap,
  .page-head_catalog .page-head__wrap {
    padding: 0 20px;
    min-height: auto;
  }
  .page-head_catalog {
    padding-bottom: 30px;
  }
  .page-head_modal {
    margin: 0;
    padding: 0;
  }
  .page-head_modal .page-head__wrap {
    padding: 50px 0;
    min-height: 250px;
  }
}
.team-label {
  margin: 0 0 30px !important;
  max-height: 39px;
}
.avatar {
  padding: 30px 0;
  margin: 0 auto 16px;
}
.avatar:after {
  content: "";
  display: block;
  position: absolute;
  top: 25px;
  left: 50%;
  width: 180px;
  height: 180px;
  border: 2px solid #E0A370;
  border-radius: 2px;
  z-index: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.avatar__wrap {
  position: relative;
  max-width: 100%;
  width: 170px;
  height: 170px;
  margin: 0 auto;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
}
.avatar__img {
  max-width: none;
  width: 240px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -moz-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  -o-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}
.avatar_min {
  padding: 16px;
}
.avatar_min:after {
  top: 13px;
  width: 60px;
  height: 60px;
}
.avatar_min .avatar__wrap {
  width: 55px;
  height: 55px;
}
.avatar_min .avatar__img {
  width: 80px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .avatar {
    padding: 26px;
  }
  .avatar:after {
    top: 21px;
    width: 136px;
    height: 136px;
  }
  .avatar__wrap {
    width: 126px;
    height: 126px;
  }
  .avatar__img {
    width: 182px;
  }
  .avatar_min {
    padding: 16px;
  }
  .avatar_min:after {
    top: 13px;
    width: 60px;
    height: 60px;
  }
  .avatar_min .avatar__wrap {
    width: 55px;
    height: 55px;
  }
  .avatar_min .avatar__img {
    width: 80px;
  }
}
button:not(.fancybox-button),
.btn {
  position: relative;
  display: inline-block;
  background-color: #E0A370;
  border: none;
  height: auto;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: normal;
  text-transform: uppercase;
  padding: 14px 24px;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
button:not(.fancybox-button),
.btn,
button:not(.fancybox-button):focus,
.btn:focus {
  color: #191919;
}
button:not(.fancybox-button) span,
.btn span,
button:not(.fancybox-button):focus span,
.btn:focus span {
  border-bottom: none;
}
button:not(.fancybox-button):hover,
.btn:hover {
  background-color: #AF7545;
  color: #191919;
}
button:not(.fancybox-button)[disabled],
.btn[disabled],
button:not(.fancybox-button).disabled,
.btn.disabled,
button:not(.fancybox-button)[disabled]:focus,
.btn[disabled]:focus,
button:not(.fancybox-button).disabled:focus,
.btn.disabled:focus,
button:not(.fancybox-button)[disabled]:hover,
.btn[disabled]:hover,
button:not(.fancybox-button).disabled:hover,
.btn.disabled:hover,
button:not(.fancybox-button)[disabled]:active,
.btn[disabled]:active,
button:not(.fancybox-button).disabled:active,
.btn.disabled:active {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}
.btn_white:not(.fancybox-button) {
  background-color: #ffffff;
}
.btn_white:not(.fancybox-button),
.btn_white:not(.fancybox-button):focus {
  color: #191919;
}
.btn_white:not(.fancybox-button):hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.btn_block:not(.fancybox-button) {
  display: block;
  width: 100%;
}
.btn_fl:not(.fancybox-button) {
  float: left;
}
.btn_note {
  margin-right: 16px;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .btn_note {
    margin-right: 0;
    width: 100%;
  }
}
.form-el,
textarea,
select,
[type="text"],
[type="email"],
[type="search"],
[type="tel"],
[type="url"],
[type="password"],
[type="number"],
[type="date"] {
  position: relative;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 0 10px;
  font-size: 15px;
  font-family: 'Fira Sans';
  line-height: 1;
  color: #ffffff;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.form-el:hover,
textarea:hover,
select:hover,
[type="text"]:hover,
[type="email"]:hover,
[type="search"]:hover,
[type="tel"]:hover,
[type="url"]:hover,
[type="password"]:hover,
[type="number"]:hover,
[type="date"]:hover {
  border-color: rgba(255, 255, 255, 0.5);
}
.form-el:focus,
textarea:focus,
select:focus,
[type="text"]:focus,
[type="email"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus {
  border-color: #ffffff;
}
.form-el::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder,
[type="text"]::-webkit-input-placeholder,
[type="email"]::-webkit-input-placeholder,
[type="search"]::-webkit-input-placeholder,
[type="tel"]::-webkit-input-placeholder,
[type="url"]::-webkit-input-placeholder,
[type="password"]::-webkit-input-placeholder,
[type="number"]::-webkit-input-placeholder,
[type="date"]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Fira Sans';
  font-size: 15px;
  line-height: 1;
}
.form-el:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder,
[type="text"]:-moz-placeholder,
[type="email"]:-moz-placeholder,
[type="search"]:-moz-placeholder,
[type="tel"]:-moz-placeholder,
[type="url"]:-moz-placeholder,
[type="password"]:-moz-placeholder,
[type="number"]:-moz-placeholder,
[type="date"]:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Fira Sans';
  font-size: 15px;
  line-height: 1;
}
.form-el:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder,
[type="text"]:-ms-input-placeholder,
[type="email"]:-ms-input-placeholder,
[type="search"]:-ms-input-placeholder,
[type="tel"]:-ms-input-placeholder,
[type="url"]:-ms-input-placeholder,
[type="password"]:-ms-input-placeholder,
[type="number"]:-ms-input-placeholder,
[type="date"]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Fira Sans';
  font-size: 15px;
  line-height: 1;
}
.form-el[readonly],
textarea[readonly],
select[readonly],
[type="text"][readonly],
[type="email"][readonly],
[type="search"][readonly],
[type="tel"][readonly],
[type="url"][readonly],
[type="password"][readonly],
[type="number"][readonly],
[type="date"][readonly],
.form-el.readonly,
textarea.readonly,
select.readonly,
[type="text"].readonly,
[type="email"].readonly,
[type="search"].readonly,
[type="tel"].readonly,
[type="url"].readonly,
[type="password"].readonly,
[type="number"].readonly,
[type="date"].readonly,
.form-el[disabled],
textarea[disabled],
select[disabled],
[type="text"][disabled],
[type="email"][disabled],
[type="search"][disabled],
[type="tel"][disabled],
[type="url"][disabled],
[type="password"][disabled],
[type="number"][disabled],
[type="date"][disabled],
.form-el.disabled,
textarea.disabled,
select.disabled,
[type="text"].disabled,
[type="email"].disabled,
[type="search"].disabled,
[type="tel"].disabled,
[type="url"].disabled,
[type="password"].disabled,
[type="number"].disabled,
[type="date"].disabled,
.form-el[readonly]:focus,
textarea[readonly]:focus,
select[readonly]:focus,
[type="text"][readonly]:focus,
[type="email"][readonly]:focus,
[type="search"][readonly]:focus,
[type="tel"][readonly]:focus,
[type="url"][readonly]:focus,
[type="password"][readonly]:focus,
[type="number"][readonly]:focus,
[type="date"][readonly]:focus,
.form-el.readonly:focus,
textarea.readonly:focus,
select.readonly:focus,
[type="text"].readonly:focus,
[type="email"].readonly:focus,
[type="search"].readonly:focus,
[type="tel"].readonly:focus,
[type="url"].readonly:focus,
[type="password"].readonly:focus,
[type="number"].readonly:focus,
[type="date"].readonly:focus,
.form-el[disabled]:focus,
textarea[disabled]:focus,
select[disabled]:focus,
[type="text"][disabled]:focus,
[type="email"][disabled]:focus,
[type="search"][disabled]:focus,
[type="tel"][disabled]:focus,
[type="url"][disabled]:focus,
[type="password"][disabled]:focus,
[type="number"][disabled]:focus,
[type="date"][disabled]:focus,
.form-el.disabled:focus,
textarea.disabled:focus,
select.disabled:focus,
[type="text"].disabled:focus,
[type="email"].disabled:focus,
[type="search"].disabled:focus,
[type="tel"].disabled:focus,
[type="url"].disabled:focus,
[type="password"].disabled:focus,
[type="number"].disabled:focus,
[type="date"].disabled:focus,
.form-el[readonly]:hover,
textarea[readonly]:hover,
select[readonly]:hover,
[type="text"][readonly]:hover,
[type="email"][readonly]:hover,
[type="search"][readonly]:hover,
[type="tel"][readonly]:hover,
[type="url"][readonly]:hover,
[type="password"][readonly]:hover,
[type="number"][readonly]:hover,
[type="date"][readonly]:hover,
.form-el.readonly:hover,
textarea.readonly:hover,
select.readonly:hover,
[type="text"].readonly:hover,
[type="email"].readonly:hover,
[type="search"].readonly:hover,
[type="tel"].readonly:hover,
[type="url"].readonly:hover,
[type="password"].readonly:hover,
[type="number"].readonly:hover,
[type="date"].readonly:hover,
.form-el[disabled]:hover,
textarea[disabled]:hover,
select[disabled]:hover,
[type="text"][disabled]:hover,
[type="email"][disabled]:hover,
[type="search"][disabled]:hover,
[type="tel"][disabled]:hover,
[type="url"][disabled]:hover,
[type="password"][disabled]:hover,
[type="number"][disabled]:hover,
[type="date"][disabled]:hover,
.form-el.disabled:hover,
textarea.disabled:hover,
select.disabled:hover,
[type="text"].disabled:hover,
[type="email"].disabled:hover,
[type="search"].disabled:hover,
[type="tel"].disabled:hover,
[type="url"].disabled:hover,
[type="password"].disabled:hover,
[type="number"].disabled:hover,
[type="date"].disabled:hover,
.form-el[readonly]:active,
textarea[readonly]:active,
select[readonly]:active,
[type="text"][readonly]:active,
[type="email"][readonly]:active,
[type="search"][readonly]:active,
[type="tel"][readonly]:active,
[type="url"][readonly]:active,
[type="password"][readonly]:active,
[type="number"][readonly]:active,
[type="date"][readonly]:active,
.form-el.readonly:active,
textarea.readonly:active,
select.readonly:active,
[type="text"].readonly:active,
[type="email"].readonly:active,
[type="search"].readonly:active,
[type="tel"].readonly:active,
[type="url"].readonly:active,
[type="password"].readonly:active,
[type="number"].readonly:active,
[type="date"].readonly:active,
.form-el[disabled]:active,
textarea[disabled]:active,
select[disabled]:active,
[type="text"][disabled]:active,
[type="email"][disabled]:active,
[type="search"][disabled]:active,
[type="tel"][disabled]:active,
[type="url"][disabled]:active,
[type="password"][disabled]:active,
[type="number"][disabled]:active,
[type="date"][disabled]:active,
.form-el.disabled:active,
textarea.disabled:active,
select.disabled:active,
[type="text"].disabled:active,
[type="email"].disabled:active,
[type="search"].disabled:active,
[type="tel"].disabled:active,
[type="url"].disabled:active,
[type="password"].disabled:active,
[type="number"].disabled:active,
[type="date"].disabled:active {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
}
textarea,
textarea.form-el {
  height: auto;
  max-width: 100%;
  max-height: 250px;
  resize: none;
  line-height: 12px;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-group {
  position: relative;
  margin-bottom: 32px;
}
.form-group.error .form-error {
  color: #ffbdb9;
}
.form-group.error .form-el,
.form-group.error textarea,
.form-group.error select,
.form-group.error [type="text"],
.form-group.error [type="email"],
.form-group.error [type="search"],
.form-group.error [type="tel"],
.form-group.error [type="url"],
.form-group.error [type="password"],
.form-group.error [type="number"],
.form-group.error [type="date"] {
  border-color: #ffbdb9;
}
.form-group.error .form-el:hover,
.form-group.error textarea:hover,
.form-group.error select:hover,
.form-group.error [type="text"]:hover,
.form-group.error [type="email"]:hover,
.form-group.error [type="search"]:hover,
.form-group.error [type="tel"]:hover,
.form-group.error [type="url"]:hover,
.form-group.error [type="password"]:hover,
.form-group.error [type="number"]:hover,
.form-group.error [type="date"]:hover {
  border-color: rgba(255, 189, 185, 0.7);
}
.form-error,
.form-info {
  display: block;
  font-size: 13px;
  margin-top: 5px;
}
.form-error_top,
.form-info_top {
  margin-top: 0;
  margin-bottom: 10px;
}
label,
.form-label {
  display: block;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
  cursor: pointer;
}
label:last-child,
.form-label:last-child {
  margin-bottom: 0;
}
label.js-dynamic-label,
.form-label.js-dynamic-label {
  position: absolute;
  left: 0;
  top: 3px;
  z-index: 3;
  margin-bottom: 0;
  -webkit-transition: all 0.15s ease-in-out 0s;
  -moz-transition: all 0.15s ease-in-out 0s;
  -o-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}
label.js-dynamic-label.focused,
.form-label.js-dynamic-label.focused {
  cursor: default;
  top: -23px;
}
.btn-group {
  display: table;
  width: 100%;
  word-spacing: -0.36em;
  margin-bottom: -20px;
}
.btn-group .link,
.btn-group .btn {
  word-spacing: normal;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  margin-bottom: 20px;
}
.btn-group .link:last-child,
.btn-group .btn:last-child {
  margin-right: 0;
}
.btn-group_min {
  margin-bottom: -10px;
}
.btn-group_min .link,
.btn-group_min .btn {
  margin-right: 10px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .form-short {
    max-width: 300px;
  }
}
.check-box {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.check-box_ml-minus {
  margin-left: -24px;
  max-width: 380px;
}
.check-box_ib {
  display: inline-block;
  margin-right: 12px;
}
.check-box_ib:last-child {
  margin-right: 0;
  margin-bottom: 12px;
}
.check-box_empty .check-inp + .check-text {
  padding: 12px;
}
.check-box_fz-main .check-label {
  font-size: 15px;
}
@media (max-width: 767px) {
  .check-box_ml-minus {
    margin-left: 0;
  }
}
.check-inp {
  display: none;
}
.check-inp + .check-text {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  min-height: 16px;
  cursor: pointer;
}
.check-inp + .check-text,
.check-inp + .check-text:after,
.check-inp + .check-text:before {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.check-inp + .check-text:after,
.check-inp + .check-text:before {
  display: block;
  left: 0;
  top: 2px;
  position: absolute;
  border-radius: 2px;
  height: 16px;
  width: 16px;
}
.check-inp + .check-text:before {
  content: "";
  background-color: transparent;
  border: 1px solid #E0A370;
}
.check-inp + .check-text:after {
  content: "\F12C";
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  color: #ffffff;
  visibility: hidden;
  opacity: 0;
}
.check-inp + .check-text:hover:before {
  border-color: #ffffff;
}
.check-inp:checked + .check-text:before {
  background-color: #E0A370;
}
.check-inp:checked + .check-text:after {
  visibility: visible;
  opacity: 1;
}
.check-inp:checked + .check-text:hover:before {
  background-color: #ffffff;
}
.check-inp[disabled] + .check-text,
.check-inp.disabled + .check-text {
  cursor: default;
}
.check-inp[disabled] + .check-text:before,
.check-inp.disabled + .check-text:before,
.check-inp[disabled] + .check-text:hover:before,
.check-inp.disabled + .check-text:hover:before {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
}
.check-inp[disabled] + .check-text:after,
.check-inp.disabled + .check-text:after {
  visibility: visible;
  opacity: 1;
}
.r-tabs {
  display: table;
  width: 100%;
  word-spacing: -0.36em;
  position: relative;
}
.r-tabs_short {
  max-width: 250px;
}
.r-tabs-label {
  word-spacing: normal;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 0;
  font-size: 15px;
}
.r-tabs-label:last-child {
  margin-right: 0;
}
.r-tabs-inp {
  display: none;
}
.r-tabs-inp + .r-tabs-text {
  display: block;
  padding-bottom: 10px;
  color: #E0A370;
  cursor: pointer;
  margin-bottom: -1px;
  border-bottom: 3px solid transparent;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.r-tabs-inp + .r-tabs-text:hover {
  color: #ffffff;
}
.r-tabs-inp:checked + .r-tabs-text {
  color: rgba(255, 255, 255, 0.7);
  border-bottom-color: #191919;
}
.form-question {
  position: relative;
  background-color: #AF7545;
  padding: 49px 0 70px;
}
.form-question:after,
.form-question:before {
  content: "";
  position: absolute;
}
.form-question:after {
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 76px;
  background: transparent url("/assets/images/patterns/pattern-brown.png") left top repeat;
  z-index: 0;
}
.form-question:before {
  display: none;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #A36C42;
  width: 27.7%;
  z-index: 1;
}
.form-question__cont {
  position: relative;
  z-index: 2;
}
.form-question__title {
  margin-bottom: 32px;
}
@media (min-width: 992px) {
  .form-question__cont {
    max-width: 72%;
  }
  .form-question__form {
    max-width: 625px;
  }
  .form-question:before {
    display: block;
  }
}
.slider-prev,
.slider-next {
  position: absolute;
  top: 0;
  z-index: 7;
  width: 50px;
  height: 50px;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.slider-prev .mdi,
.slider-next .mdi {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  font-size: 60px;
  line-height: 1;
  text-align: center;
}
.slider-next {
  right: 0;
}
.slider-prev {
  left: 0;
}
.promo-slider-box {
  position: relative;
}
.promo-slider {
  position: relative;
  height: auto;
  color: #ffffff;
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
}
.promo-slider__wrapper,
.promo-slider__slide {
  height: 100%;
}
.promo-slider__slide {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  cursor: default;
  padding: 0;
  -webkit-transition: opacity 0.3s ease-in-out 0s;
  -moz-transition: opacity 0.3s ease-in-out 0s;
  -o-transition: opacity 0.3s ease-in-out 0s;
  transition: opacity 0.3s ease-in-out 0s;
}
.promo-slider__slide,
.promo-slider__slide:focus,
.promo-slider__slide:hover {
  color: #ffffff;
}
.promo-slider__slide span,
.promo-slider__slide:focus span,
.promo-slider__slide:hover span {
  border-bottom: none;
}
.promo-slider__slide.link {
  cursor: pointer;
}
.promo-slider__slide.link:hover {
  opacity: .9;
}
.promo-slider__next,
.promo-slider__prev {
  position: relative;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
}
.promo-slider__next:hover,
.promo-slider__prev:hover {
  opacity: .6;
}
.promo-slider__next.swiper-button-disabled,
.promo-slider__prev.swiper-button-disabled,
.promo-slider__next.swiper-button-disabled:hover,
.promo-slider__prev.swiper-button-disabled:hover {
  opacity: .2;
  cursor: default;
}
.view-slider-offset {
  margin-bottom: -81px;
}
.view-slider-box {
  margin-bottom: 30px;
  height: 560px;
}
.view-slider-box .view-slider {
  margin-bottom: 0;
}
.view-slider-box_catalog {
  height: 600px;
  position: relative;
  top: 81px;
  margin: 0 auto !important;
}
.view-slider-box_building {
  margin-left: auto !important;
  margin-right: auto !important;
  height: 500px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .view-slider-box {
    height: 505px;
    margin-left: -72px;
    margin-right: -72px;
  }
  .view-slider-box_catalog,
  .view-slider-box_building {
    height: 380px;
  }
}
@media (max-width: 767px) {
  .view-slider-box {
    height: 250px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.view-slider {
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.3s ease-in-out 0s;
  -moz-transition: opacity 0.3s ease-in-out 0s;
  -o-transition: opacity 0.3s ease-in-out 0s;
  transition: opacity 0.3s ease-in-out 0s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.view-slider__desc {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px 20px 8px;
  bottom: 0;
  max-width: 870px;
  height: 90px;
  margin: 0 auto;
  background-image: -webkit-linear-gradient(90deg, #282828 0%, rgba(40, 40, 40, 0) 100%);
  background-image: -moz-linear-gradient(90deg, #282828 0%, rgba(40, 40, 40, 0) 100%);
  background-image: -ms-linear-gradient(90deg, #282828 0%, rgba(40, 40, 40, 0) 100%);
  background-image: -o-linear-gradient(90deg, #282828 0%, rgba(40, 40, 40, 0) 100%);
  background-image: linear-gradient(0deg, #282828 0%, rgba(40, 40, 40, 0) 100%);
}
.view-slider__desc .tb__c {
  vertical-align: bottom;
}
.rsFullscreen .view-slider__desc {
  width: 100%;
  max-width: 100%;
}
.view-slider__slide {
  height: 100%;
}
.view-slider_bg {
  background-color: #282828;
}
.view-slider.not-visible,
.view-slider.in-modal {
  opacity: 0;
}
.view-slider.rsHor .rsArrowLeft {
  left: 17px;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.view-slider.rsHor .rsArrowRight {
  right: 17px;
}
.view-slider .rsImg {
  border-radius: 2px;
  margin: auto;
}
.view-slider .rsOverflow {
  border-radius: 2px;
  background-color: transparent;
}
.view-slider.rsFullscreen {
  max-width: none;
}
.view-slider .rsPreloader {
  width: 20px;
  height: 20px;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
.view-slider-text {
  text-align: center;
  margin-top: 10px;
}
.rsImg {
  max-height: 100%;
}
.rsFullscreenBtn {
  display: block;
  position: absolute;
  right: 28px;
  top: 28px;
  height: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 2px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  opacity: 1;
  z-index: 21;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.rsFullscreenBtn .rsFullscreenIcn {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
  width: 18px;
  height: 18px;
  line-height: 1;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.rsFullscreenBtn .rsFullscreenIcn:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1px;
  margin-top: -1px;
  width: 2px;
  height: 2px;
  background-color: #ffffff;
}
.rsFullscreenBtn:hover .rsFullscreenIcn {
  border-color: #ffffff;
  opacity: 1;
}
.rsFullscreen .rsFullscreenBtn {
  border: none;
  width: 32px;
  height: 32px;
}
.rsFullscreen .rsFullscreenBtn:after {
  content: "\F156";
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  font-size: 32px;
  line-height: 32px;
}
.rsFullscreen .rsFullscreenBtn .rsFullscreenIcn {
  display: none;
}
.rsFullscreen .rsFullscreenBtn:hover {
  opacity: .8;
}
.rsOverflow {
  z-index: 21;
}
.rsArrow {
  display: block;
  position: absolute;
  top: 40%;
  width: 35px;
  height: 45px;
  line-height: 45px;
  border-radius: 2px;
  background-color: transparent;
  color: #ffffff;
  text-align: center;
  z-index: 21;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.rsArrow:after,
.rsArrow:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  background-color: #ffffff;
}
.rsArrow:after {
  top: 11px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.rsArrow:before {
  bottom: 11px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.rsArrow .rsArrowIcn {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -9px;
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
  width: 18px;
  height: 18px;
  line-height: 1;
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.rsArrow .rsArrowIcn:after {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #ffffff;
}
.rsArrow:hover {
  color: #ffffff;
}
.rsArrow:hover .rsArrowIcn {
  border-color: #ffffff;
}
.rsArrow.rsArrowDisabled,
.rsArrow.swiper-button-disabled {
  opacity: .2;
  cursor: default;
}
.rsArrow.rsArrowDisabled .rsArrowIcn,
.rsArrow.swiper-button-disabled .rsArrowIcn,
.rsArrow.rsArrowDisabled:hover .rsArrowIcn,
.rsArrow.swiper-button-disabled:hover .rsArrowIcn {
  border-color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 767px) {
  .rsArrow {
    top: 35%;
  }
}
.rsThumbs {
  position: relative;
  width: 100%;
  height: 81px;
  padding: 6px 0;
  z-index: 2;
}
.rsThumbs .rsThumb {
  position: relative;
  overflow: hidden;
  height: 69px;
  width: 95px;
  border: none;
  border-radius: 2px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.rsThumbs .rsThumb:hover {
  background-color: rgba(25, 25, 25, 0.4);
}
.rsThumbs .rsThumb.rsNavSelected,
.rsThumbs .rsThumb.rsNavSelected:hover {
  background-color: rgba(25, 25, 25, 0.4);
  -webkit-box-shadow: inset 0 0 0 4px #E0A370;
  -moz-box-shadow: inset 0 0 0 4px #E0A370;
  box-shadow: inset 0 0 0 4px #E0A370;
}
.rsThumbs .rsThumb .rsTmb {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.fullscreen-slider {
  overflow: hidden;
}
.fullscreen-slider__arrow {
  position: fixed;
  top: auto;
  bottom: 20px;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1000;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation: move-y 1.8s ease infinite running;
  -moz-animation: move-y 1.8s ease infinite running;
  animation: move-y 1.8s ease infinite running;
}
.fullscreen-slider__arrow .rsArrowIcn {
  margin-top: -10px;
}
@media (max-width: 767px) {
  .fullscreen-slider__arrow {
    display: none;
  }
}
.social {
  display: table;
  width: 100%;
  word-spacing: -0.36em;
  font-size: 24px;
  margin-bottom: -10px;
}
.social__el {
  white-space: normal;
  vertical-align: top;
  margin-right: 16px;
  margin-bottom: 10px;
}
.social__el:last-child {
  margin-right: 0;
}
.social__el,
.social__el:focus {
  color: rgba(255, 255, 255, 0.7);
}
.social__el:hover {
  color: #E0A370;
  -webkit-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.social_building {
  font-size: 36px;
}
.social_building .social__el,
.social_building .social__el:focus {
  color: #E0A370;
}
.social_building .social__el:hover {
  color: #A36C42;
}
.mtoggle {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  min-width: 32px;
  line-height: 1;
  z-index: 100;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
.mtoggle,
.mtoggle:focus,
.mtoggle:hover {
  color: #E0A370;
}
.mtoggle span,
.mtoggle:focus span,
.mtoggle:hover span {
  border-bottom: none;
}
.mtoggle:hover {
  color: #E0A370;
}
.mtoggle:hover .mtoggle__icon:after {
  margin-top: 10px;
}
.mtoggle:hover .mtoggle__icon:before {
  margin-top: -10px;
}
.mtoggle__icon {
  top: 50%;
  left: 0;
  margin-top: -2px;
}
.mtoggle__icon,
.mtoggle__icon:after,
.mtoggle__icon:before {
  display: block;
  background-color: #E0A370;
  position: absolute;
  width: 26px;
  height: 2px;
  border-radius: 2px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mtoggle:hover .mtoggle__icon,
.mtoggle:hover .mtoggle__icon:after,
.mtoggle:hover .mtoggle__icon:before {
  background-color: #E0A370;
}
.mtoggle__icon:after,
.mtoggle__icon:before {
  content: "";
  top: 0;
}
.mtoggle__icon:after {
  margin-top: 8px;
}
.mtoggle__icon:before {
  margin-top: -8px;
}
.mtoggle__text {
  display: block;
  position: relative;
  top: 50%;
  margin-top: -7px;
  padding-left: 36px;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 1.33px;
  font-weight: 300;
  color: #ffffff;
}
.mtoggle.open .mtoggle__icon {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  background-color: transparent;
}
.mtoggle.open .mtoggle__icon:after,
.mtoggle.open .mtoggle__icon:before {
  margin: 0;
}
.mtoggle.open .mtoggle__icon:after {
  top: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mtoggle.open .mtoggle__icon:before {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
@media (max-width: 767px) {
  .mtoggle {
    left: 10px;
  }
  .mtoggle__text {
    display: none;
  }
  .mtoggle__icon {
    left: 50%;
    margin-left: -13px;
  }
}
.aside-wrap {
  position: relative;
}
.svg-map {
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.svg-map__wrap {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.svg-map__svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.svg-map__el {
  fill: transparent;
  stroke: transparent;
  stroke-width: 2px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-tap-highlight-color: transparent;
}
.svg-map__el:hover,
.svg-map__el.hovered {
  fill: rgba(224, 163, 112, 0.7);
  stroke: #E0A370;
}
.has-touch .svg-map__el {
  fill: rgba(224, 163, 112, 0.2);
  stroke: rgba(224, 163, 112, 0.5);
}
.has-touch .svg-map__el:hover,
.has-touch .svg-map__el.hovered {
  fill: rgba(224, 163, 112, 0.7);
}
.svg-map__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 120px;
  z-index: 1;
  padding-top: 23px;
  padding-bottom: 20px;
  background-image: -webkit-linear-gradient(-90deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
  background-image: -moz-linear-gradient(-90deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
  background-image: -ms-linear-gradient(-90deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
  background-image: -o-linear-gradient(-90deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
  background-image: linear-gradient(180deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
}
.svg-map__text {
  font-family: 'Oranienbaum';
  font-size: 24px;
}
.svg-map_main .svg-map__el {
  fill: rgba(224, 163, 112, 0.4);
  stroke: #E0A370;
}
.svg-map_main .svg-map__el:hover,
.svg-map_main .svg-map__el.hovered {
  fill: rgba(224, 163, 112, 0.7);
}
.svg-map_main .svg-map-num {
  -webkit-transition: none 0.2s ease-in-out 0s;
  -moz-transition: none 0.2s ease-in-out 0s;
  -o-transition: none 0.2s ease-in-out 0s;
  transition: none 0.2s ease-in-out 0s;
}
.svg-map_main .svg-map-num,
.svg-map_main .svg-map-num:focus,
.svg-map_main .svg-map-num:hover {
  color: #191919;
}
.svg-map_main .svg-map-num:after,
.svg-map_main .svg-map-num:focus:after,
.svg-map_main .svg-map-num:hover:after {
  background-color: #E0A370;
}
.svg-map_main .svg-map-num span,
.svg-map_main .svg-map-num:focus span,
.svg-map_main .svg-map-num:hover span {
  border-bottom: none;
}
@media (max-width: 991px) {
  .svg-map__content {
    display: block;
    position: relative;
    min-height: auto;
  }
}
@media (max-width: 767px) {
  .svg-map .svg-map__el {
    fill: rgba(224, 163, 112, 0.2);
    stroke: #E0A370;
  }
  .svg-map_main .svg-map__el {
    stroke-width: 4px;
    fill: rgba(224, 163, 112, 0.4);
  }
  .svg-map_main .svg-map-num {
    font-size: 13px;
    width: 18px;
    height: 18px;
  }
  .svg-map_main .svg-map-num span {
    line-height: 18px;
  }
}
.svg-map-num {
  position: absolute;
  display: block;
  z-index: 20;
  font-family: 'Oranienbaum';
  width: 34px;
  height: 34px;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  fill: #E0A370;
}
.svg-map-num:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: transparent;
}
.svg-map-num:hover {
  color: #191919;
}
.svg-map-num:hover:after {
  background-color: #E0A370;
}
.svg-map-num span {
  position: relative;
  line-height: 34px;
  z-index: 1;
}
.has-touch .svg-map-num {
  display: none !important;
}
.minimap {
  position: absolute;
  top: 100px;
  left: 40px;
  width: 134px;
  height: 184px;
  z-index: 20;
  background: transparent url('/assets/images/patterns/minimap.png') center no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.minimap__svg {
  display: block;
  position: absolute;
}
.minimap__svg_1 {
  top: 0;
  left: 73px;
}
.minimap__svg_2 {
  top: 89px;
  left: 28px;
}
.minimap__svg_3 {
  top: 114px;
  left: 15px;
}
.minimap__svg_4 {
  top: 64px;
  left: 55px;
}
.minimap__svg-link {
  stroke: rgba(255, 255, 255, 0.7);
  stroke-width: 2px;
  fill: transparent;
}
.minimap__svg-link:hover {
  stroke: #ffffff;
}
.minimap__svg-link.active,
.minimap__svg-link.active:hover {
  cursor: default;
  stroke: #e0a370;
  fill: rgba(224, 163, 112, 0.3);
}
@media (max-width: 991px) {
  .minimap {
    display: none;
  }
}
.minimap-floors {
  position: absolute;
  top: 100px;
  left: 190px;
  max-width: 50px;
  z-index: 21;
}
@media (min-width: 768px) and (max-width: 991px) {
  .minimap-floors {
    left: 20px;
  }
}
@media (max-width: 767px) {
  .minimap-floors {
    display: none;
  }
}
.compass {
  position: absolute;
  top: 100px;
  right: 72px;
  max-width: 110px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .compass {
    top: 70px;
    right: 5px;
  }
}
@media (max-width: 767px) {
  .compass {
    display: none;
  }
}
.list-slider-box {
  position: relative;
  text-align: center;
  padding: 0 72px;
}
@media (max-width: 767px) {
  .list-slider-box {
    padding: 0 20px;
  }
}
.list-slider {
  display: inline-block;
  max-width: 100%;
}
.list-slider__slide {
  width: 260px;
}
.list-slider__prev {
  left: 17px;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.list-slider__next {
  right: 17px;
}
@-moz-keyframes scale-up {
  0%,
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.3, 1.3);
    -moz-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    -o-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }
}
@-webkit-keyframes scale-up {
  0%,
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.3, 1.3);
    -moz-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    -o-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }
}
@keyframes scale-up {
  0%,
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.3, 1.3);
    -moz-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    -o-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }
}
@-moz-keyframes bgMove-x {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 98px;
  }
}
@-webkit-keyframes bgMove-x {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 98px;
  }
}
@keyframes bgMove-x {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 98px;
  }
}
@-moz-keyframes move-y {
  0%,
  100% {
    bottom: 20px;
  }
  50% {
    bottom: 35px;
  }
}
@-webkit-keyframes move-y {
  0%,
  100% {
    bottom: 20px;
  }
  50% {
    bottom: 35px;
  }
}
@keyframes move-y {
  0%,
  100% {
    bottom: 20px;
  }
  50% {
    bottom: 35px;
  }
}
.home-sec {
  overflow: hidden;
  margin-bottom: 8px;
}
.home-sec__wrap {
  display: block;
  padding-top: 140px;
  padding-bottom: 164px;
}
.home-sec__wrap-min {
  display: block;
  padding-top: 50px;
  padding-bottom: 50px;
}
.home-sec:last-child {
  margin-bottom: 0;
}
.home-sec__logo {
  max-height: 150px;
  margin: 0 auto 25px;
}
.home-sec__title {
  max-width: 905px;
  margin-left: auto;
  margin-right: auto;
}
.home-sec__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .4;
  z-index: 0;
  background: transparent center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.home-sec__img.aos-animate {
  opacity: .4 !important;
}
.home-sec__video {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background: #191919 url('/assets/video/poster.jpg') no-repeat;
  z-index: -1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.home-sec__video-box {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  opacity: .4;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.home-sec__advans {
  max-width: 570px;
  margin: 0 auto;
}
.home-sec__arch-logo {
  margin: 0 0 24px !important;
  max-height: 77px;
}
.home-sec__actions {
  margin-top: 40px;
}
.home-sec_center {
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .home-sec__arch-logo {
    position: absolute;
    top: 0;
    left: 0;
  }
  .home-sec__arch-text {
    position: relative;
    padding-left: 120px;
    min-height: 78px;
    margin-top: 24px;
  }
}
@media (max-width: 767px) {
  .home-sec {
    height: auto !important;
  }
  .home-sec .fp-tableCell {
    height: auto !important;
  }
  .home-sec:last-child .home-sec__wrap {
    padding-bottom: 260px;
  }
  .home-sec__wrap {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .home-sec__logo {
    margin-top: -25px;
    height: 120px;
  }
  .home-sec__advans {
    margin-bottom: -20px;
  }
  .home-sec__actions {
    margin-top: 20px;
  }
  .home-sec__arch {
    text-align: center;
  }
  .home-sec__arch-logo {
    margin: 0 auto 16px !important;
  }
  .home-sec__arch-text {
    margin-top: 20px;
  }
  .home-sec__xs-center {
    text-align: center;
  }
}
.home-arch-link {
  position: relative;
  max-width: 310px;
  padding-left: 60px;
  min-height: 40px;
  margin-top: 16px;
}
.home-arch-link__icon {
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
  -moz-transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
  -o-transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
  transition: all 0.5s cubic-bezier(1, 0, 0, 0.5) 0s;
}
.home-arch-link:hover .home-arch-link__icon {
  -webkit-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
@media (min-width: 768px) and (max-width: 991px) {
  .home-arch-link {
    margin-top: 0;
  }
}
.home-advans,
.home-advans__el {
  position: relative;
  height: 100%;
}
.home-advans__wrap,
.home-advans__wrap-min,
.home-advans .advan-el {
  display: block;
}
.home-advans .home-sec__img {
  display: block;
}
.home-advans__el {
  overflow: hidden;
}
.home-advans__el span,
.home-advans__el:focus span {
  border-bottom: none;
}
.home-advans__el:hover .home-sec__img {
  opacity: .2 !important;
}
.home-advans__wrap {
  position: relative;
  z-index: 1;
  text-align: center;
}
@media (max-width: 991px) {
  .home-advans__el {
    height: 32.7%;
    margin-bottom: 1%;
    text-align: left;
  }
  .home-advans__el:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .home-advans__el {
    text-align: center;
  }
  .home-advans__wrap {
    text-align: left;
  }
  .home-advans__wrap .pretitle-icon {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .home-advans__el {
    height: auto;
    margin-bottom: 8px;
  }
}
.about-map {
  position: relative;
  text-align: center;
}
.about-map__cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 230px;
  padding: 72px 20px 0;
  z-index: 1;
  background-image: -webkit-linear-gradient(-90deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
  background-image: -moz-linear-gradient(-90deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
  background-image: -ms-linear-gradient(-90deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
  background-image: -o-linear-gradient(-90deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
  background-image: linear-gradient(180deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
}
.about-map__text {
  max-width: 665px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .about-map__cont {
    min-height: auto;
    padding-top: 48px;
  }
  .about-map__cont .text {
    display: none;
  }
}
.objects-map {
  height: 680px;
}
@media (max-width: 767px) {
  .objects-map {
    height: 460px;
  }
}
.info-window-text {
  text-align: center;
  color: #191919;
  font-size: 18px;
  font-weight: 400;
}
.catalog-el {
  text-align: center;
  margin-bottom: 40px;
}
.catalog-el,
.catalog-el__wrap {
  position: relative;
}
.catalog-el span,
.catalog-el:focus span {
  border-bottom: none;
}
.catalog-el__wrap {
  display: block;
  background-color: #282828;
}
.catalog-el__cont {
  padding: 30px;
  height: 360px;
  position: relative;
  z-index: 1;
}
.catalog-el__note,
.catalog-el__text,
.catalog-el__advans,
.catalog-el .advan-el__text {
  display: block;
  color: rgba(255, 255, 255, 0.7);
}
.catalog-el__note {
  margin-bottom: 2px;
}
.catalog-el__title {
  display: block;
  margin-bottom: 20px;
}
.catalog-el__img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: .4;
  background: transparent center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.catalog-el:hover .catalog-el__img {
  opacity: .2;
}
.catalog-el .advan-el {
  display: inline-block;
  vertical-align: top;
  margin-right: 7%;
  margin-bottom: 20px;
  max-width: 165px;
}
.catalog-el .advan-el:last-child {
  margin-right: 0;
}
@media (min-width: 992px) {
  .catalog-el:nth-child(1) .catalog-el__cont {
    height: 780px;
  }
  .catalog-el:nth-child(2) .catalog-el__cont,
  .catalog-el:nth-child(3) .catalog-el__cont {
    height: 370px;
  }
}
@media (max-width: 767px) {
  .catalog-el {
    margin-bottom: 20px;
  }
  .catalog-el__cont {
    height: auto;
  }
}
.contacts {
  margin-top: -100px;
  margin-bottom: 0;
}
.contacts__wrap {
  padding-top: 136px;
}
.contacts__title {
  margin-bottom: 36px;
}
@media (max-width: 991px) {
  .contacts__title {
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .contacts__wrap {
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .contacts {
    margin-top: -68px;
  }
  .contacts__wrap {
    padding-top: 88px;
    padding-bottom: 20px;
  }
  .contacts__title {
    margin-bottom: 20px;
  }
}
.contacts-map {
  width: 100%;
  height: 515px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .contacts-map {
    height: 480px;
  }
}
@media (max-width: 767px) {
  .contacts-map {
    height: 380px;
  }
}
.contacts-el {
  margin-bottom: 35px;
  font-size: 18px;
  max-width: 350px;
}
.contacts-el__item {
  margin-bottom: 6px;
}
.contacts-el__item:last-child {
  margin-bottom: 0;
}
.contacts-el__min-title,
.contacts-el__title {
  position: relative;
  font-weight: 600;
  color: #ffffff;
}
.contacts-el__title:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: -30px;
  width: 16px;
  height: 16px;
  border: 4px solid #E0A370;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.contacts-el__title_icon-white:after {
  border-color: #ffffff;
}
.contacts-el__min-title {
  margin-bottom: 15px;
}
.contacts-el_fz-main {
  font-size: 15px;
}
@media (max-width: 767px) {
  .contacts-el {
    font-size: 15px;
  }
  .contacts-el__wrap {
    position: relative;
    padding-left: 30px;
  }
}
.contacts-form .form-question:before {
  display: none;
}
.contacts-form .form-question__cont {
  max-width: 100%;
}
.view-price {
  font-family: 'Oranienbaum';
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 16px;
}
.view-price a,
.view-price {
  color: #ffffff !important;
}
.view-sec {
  margin-bottom: 40px;
}
.progress-percent {
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  z-index: 5;
  font-size: 18px;
}
.building {
  max-width: 910px;
  padding: 30px 0 50px;
  border-top: 2px solid #E0A370;
}
.building:first-of-type {
  margin-top: 50px;
}
.building:last-child {
  padding-bottom: 0;
}
.building__date-num {
  font-family: 'Oranienbaum';
  font-size: 64px;
  line-height: 1;
  color: #E0A370;
}
@media (max-width: 767px) {
  .building {
    padding: 24px 0 20px;
  }
  .building:first-of-type {
    margin-top: 32px;
  }
  .building__date {
    text-align: center;
    margin-bottom: 16px;
  }
  .building__date-num {
    font-size: 48px;
  }
  .building__title {
    text-align: center;
  }
}
.building-social {
  margin-top: 56px;
}
@media (max-width: 767px) {
  .building-social {
    margin-top: 32px;
  }
}
.progress {
  position: relative;
  display: block;
  height: 72px;
  background-color: #6D411D;
  overflow: hidden;
}
.progress:after {
  content: "";
  display: block;
  clear: both;
}
.progress__bg,
.progress__scale {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.progress__bg {
  background: transparent url('/assets/images/patterns/pattern-white-rotate.png') 0 center repeat;
  z-index: 1;
  opacity: .1;
  width: 100%;
  -webkit-animation: bgMove-x 1.6s linear infinite running;
  -moz-animation: bgMove-x 1.6s linear infinite running;
  animation: bgMove-x 1.6s linear infinite running;
}
.progress__scale {
  background-color: #AF7545;
  z-index: 0;
}
.progress__scale:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: -72px;
  border: 36px solid transparent;
  border-left: 36px solid #AF7545;
}
.progress__el {
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  display: block;
  height: 100%;
  float: left;
  padding: 0 24px;
  z-index: 5;
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid #AF7545;
}
.progress__el:last-child {
  border-right: none;
}
.progress__wrap {
  height: 100%;
}
.progress__text {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.progress__text span {
  display: inline;
  max-width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .progress__el {
    display: none;
    width: 50% !important;
    border-right: none;
  }
  .progress__el:first-child,
  .progress__el:last-child {
    display: block;
  }
  .progress__el:last-child {
    text-align: right;
  }
  .progress__el:last-child .progress__text {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .progress {
    height: 40px;
  }
  .progress__scale:after {
    right: -40px;
    border: 20px solid transparent;
    border-left: 20px solid #AF7545;
  }
  .progress__el {
    display: none;
  }
}
#fp-nav.fp-left {
  left: 20px;
}
#fp-nav ul li {
  margin: 0 0 16px;
}
#fp-nav ul li:last-child {
  margin-bottom: 0;
}
#fp-nav ul li a {
  width: 14px;
  height: 14px;
  border: 2px solid #ffffff;
  opacity: .5;
}
#fp-nav ul li a span {
  display: none;
}
#fp-nav ul li a:hover {
  opacity: 1;
}
#fp-nav ul li a.active,
#fp-nav ul li a.active:hover {
  background-color: #E0A370;
  opacity: 1;
  border-color: #E0A370;
  -webkit-transform: rotate(135deg) scale(1.22, 1.22);
  -moz-transform: rotate(135deg) scale(1.22, 1.22);
  -ms-transform: rotate(135deg) scale(1.22, 1.22);
  -o-transform: rotate(135deg) scale(1.22, 1.22);
  transform: rotate(135deg) scale(1.22, 1.22);
}
.ms-select .form-label {
  margin-bottom: 5px;
}
.ms-select .ms-choice:not(.fancybox-button) {
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 0 8px;
  font-size: 15px;
  font-family: 'Fira Sans';
  font-weight: normal;
  line-height: 1;
  color: #ffffff !important;
  background-color: transparent;
  text-transform: none;
  letter-spacing: normal;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
  text-align: left;
}
.ms-select .ms-choice:not(.fancybox-button),
.ms-select .ms-choice:not(.fancybox-button):hover,
.ms-select .ms-choice:not(.fancybox-button):focus {
  color: rgba(255, 255, 255, 0.7);
  background: transparent;
}
.ms-select .ms-choice:not(.fancybox-button):hover {
  border-color: rgba(255, 255, 255, 0.5);
}
.ms-select .ms-choice:not(.fancybox-button):focus {
  border-color: #ffffff;
}
.ms-select .ms-choice:not(.fancybox-button) > span {
  top: 4px;
  right: 0;
  padding: 0;
}
.ms-select .ms-choice:not(.fancybox-button) > span.placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Fira Sans';
  font-size: 15px;
  line-height: 1;
}
.ms-select .ms-choice:not(.fancybox-button) > div {
  width: 24px;
  height: 100%;
  background: none;
}
.ms-select .ms-choice:not(.fancybox-button) > div:after {
  content: "\F140";
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  font-size: 24px;
  -webkit-transition: -webkit-transform 0.3s ease-in-out 0s;
  -moz-transition: -moz-transform 0.3s ease-in-out 0s;
  -o-transition: -o-transform 0.3s ease-in-out 0s;
  transition: -ms-transform 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0s;
}
.ms-select .ms-choice:not(.fancybox-button) > div.open:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ms-select .ms-drop {
  border-radius: 0;
  border: none;
  padding: 0;
  background-color: #191919;
}
.ms-select .ms-drop ul {
  padding: 10px 0;
}
.ms-select .ms-drop ul > li label {
  white-space: normal;
  padding: 8px 20px;
  line-height: 1.35;
  cursor: pointer;
}
.ms-select .ms-drop ul > li label:hover {
  background: rgba(255, 255, 255, 0.1);
}
.ms-select .ms-drop ul > li.selected label,
.ms-select .ms-drop ul > li.selected label:hover {
  background: rgba(255, 255, 255, 0.15);
}
.ms-select .ms-drop input[data-name] {
  position: absolute;
  opacity: 0;
}
.ms-select .ms-drop input[data-name] + span {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  min-height: 16px;
  cursor: pointer;
}
.ms-select .ms-drop input[data-name] + span,
.ms-select .ms-drop input[data-name] + span:after,
.ms-select .ms-drop input[data-name] + span:before {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.ms-select .ms-drop input[data-name] + span:after,
.ms-select .ms-drop input[data-name] + span:before {
  content: "";
  display: block;
  left: 0;
  top: 2px;
  position: absolute;
  border-radius: 2px;
  height: 16px;
  width: 16px;
}
.ms-select .ms-drop input[data-name] + span:before {
  background-color: transparent;
  border: 1px solid #E0A370;
}
.ms-select .ms-drop input[data-name] + span:after {
  visibility: hidden;
  opacity: 0;
}
.ms-select .ms-drop input[data-name] + span:hover:before {
  border-color: #ffffff;
}
.ms-select .ms-drop input[data-name]:checked + span:before {
  background-color: #E0A370;
}
.ms-select .ms-drop input[data-name]:checked + span:after {
  visibility: visible;
  opacity: 1;
}
.ms-select .ms-drop input[data-name]:checked + span:hover:before {
  background-color: #ffffff;
}
.ms-select .ms-drop input[data-name][disabled] + span,
.ms-select .ms-drop input[data-name].disabled + span {
  cursor: default;
}
.ms-select .ms-drop input[data-name][disabled] + span:before,
.ms-select .ms-drop input[data-name].disabled + span:before,
.ms-select .ms-drop input[data-name][disabled] + span:hover:before,
.ms-select .ms-drop input[data-name].disabled + span:hover:before {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
}
.ms-select .ms-drop input[data-name][disabled] + span:after,
.ms-select .ms-drop input[data-name].disabled + span:after {
  visibility: visible;
  opacity: 1;
}
.ms-select_single .ms-drop input[data-name] + span {
  padding: 0;
}
.ms-select_single .ms-drop input[data-name] + span:after,
.ms-select_single .ms-drop input[data-name] + span:before {
  display: none;
}
